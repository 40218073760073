import classNames from 'classnames';
import React, { useEffect, useMemo } from 'react';

import { ReactComponent as Arrow } from 'assets/arrow-without-body.svg';
import { useAppDispatch } from 'hooks/useAppDispatch';
import usePagination from 'hooks/usePagination';

import './Pagination.scss';
import { setOffset } from 'store/reducers/pagination/paginationSlice';

interface PaginationProps {
    count: number; //количество элементов всего
    offset: number; //индекс первого элемента на странице
    limit: number; //количество элементов на странице
}

const Pagination: React.FC<PaginationProps> = ({ limit, offset, count }) => {
    const dispatch = useAppDispatch();

    const { setPage, page, totalPages, prevPage, nextPage } = usePagination({ limit, offset, count });

    useEffect(() => {
        if (page > totalPages && totalPages > 0) {
            setPage(1);
        } else {
            dispatch(setOffset(limit * (page - 1)));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, limit, page, totalPages]);

    //     const search = { currentPage: page.toString(), contentPerPage: numContentPerPage.toString() };
    //     setSearchParams(search);

    //     dispatch(setFirstContentIndex(firstContentIndex));
    //     dispatch(setLastContentIndex(lastContentIndex));
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [firstContentIndex, lastContentIndex, page, numContentPerPage]);

    // const onFormSubmit = (e: any) => {
    //     e.preventDefault();
    //     if (pageRef.current) {
    //         setPage(parseInt(pageRef.current.value));
    //     }
    // };

    const renderListOfPages = useMemo(() => {
        if (totalPages <= 6) {
            /* @ts-ignore */
            return [...Array(totalPages).keys()].map((num) => (
                <button
                    onClick={() => setPage(num + 1)}
                    key={num}
                    className={classNames('page_number', { 'page_number--active': page === num + 1 })}
                >
                    {num + 1}
                </button>
            ));
        } else {
            return (
                <>
                    {(() => {
                        if (page === totalPages) {
                            /* @ts-ignore */
                            return [...Array(totalPages).keys()].slice(page - 5).map((num) => (
                                <button
                                    onClick={() => setPage(num + 1)}
                                    key={num}
                                    className={classNames('page_number', {
                                        'page_number--active': page === num + 1,
                                    })}
                                >
                                    {num + 1}
                                </button>
                            ));
                        } else if (page <= 2) {
                            /* @ts-ignore */
                            return [...Array(totalPages).keys()].slice(0, 5).map((num) => (
                                <button
                                    onClick={() => setPage(num + 1)}
                                    key={num}
                                    className={classNames('page_number', {
                                        'page_number--active': page === num + 1,
                                    })}
                                >
                                    {num + 1}
                                </button>
                            ));
                        } else if (totalPages - page <= 2) {
                            /* @ts-ignore */
                            return [...Array(totalPages).keys()].slice(totalPages - 5, totalPages).map((num) => (
                                <button
                                    onClick={() => setPage(num + 1)}
                                    key={num}
                                    className={classNames('page_number', {
                                        'page_number--active': page === num + 1,
                                    })}
                                >
                                    {num + 1}
                                </button>
                            ));
                        } else {
                            /* @ts-ignore */
                            return [...Array(totalPages).keys()].slice(page - 3, page + 2).map((num) => (
                                <button
                                    onClick={() => setPage(num + 1)}
                                    key={num}
                                    className={classNames('page_number', {
                                        'page_number--active': page === num + 1,
                                    })}
                                >
                                    {num + 1}
                                </button>
                            ));
                        }
                    })()}
                    {totalPages - page > 3 && <span className='page_divider'>...</span>}
                    {totalPages - page > 2 && page < totalPages - 1 && (
                        <button
                            onClick={() => setPage(totalPages)}
                            key={totalPages}
                            className={classNames('page_number', {
                                'page_number--active': page === totalPages,
                            })}
                        >
                            {totalPages}
                        </button>
                    )}
                </>
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, totalPages]);

    return (
        <div className='pagination_wrapper'>
            <div
                onClick={prevPage}
                className={classNames('arrow', {
                    'arrow--disabled': page === 1,
                })}
            >
                <Arrow className='arrow--left' />
            </div>

            {renderListOfPages}

            <div
                onClick={nextPage}
                className={classNames('arrow', {
                    'arrow--disabled': page === totalPages,
                })}
            >
                <Arrow className='arrow--right ' />
            </div>
        </div>
    );
};

export default React.memo(Pagination);
