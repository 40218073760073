import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import TranscriptControlMenu from './controlMenu/TranscriptControlMenu';
import TranscriptName from './transcriptName/TranscriptName';

import { useLazyGetFileMetadataQuery } from 'api/routes/RecordsApi';
import { resultsApi, useEditResultMutation } from 'api/routes/ResultsApi';
import { ReactComponent as ArrowIcon } from 'assets/arrow-without-body.svg';
import { ReactComponent as CloseIcon } from 'assets/cross.svg';
// import { ReactComponent as CleanIcon } from 'assets/transcript/clean.svg';
import { ReactComponent as BackArrowIcon } from 'assets/transcript/back-arrow.svg';
import { ReactComponent as CompareIcon } from 'assets/transcript/compare.svg';
import { ReactComponent as DecreaseFont } from 'assets/transcript/decrease-font.svg';
// import { ReactComponent as HighlightIcon } from 'assets/transcript/highlight.svg';
import { ReactComponent as IncreaseFont } from 'assets/transcript/increase-font.svg';
// import { ReactComponent as InexactIcon } from 'assets/transcript/inexact.svg';
import { ReactComponent as LogoIcon } from 'assets/transcript/logo.svg';
// import { ReactComponent as ProjectsIcon } from 'assets/transcript/projects.svg';
// import { ReactComponent as ProjectsIcon } from 'assets/transcript/projects.svg';
import { ReactComponent as ProtocolIcon } from 'assets/transcript/protocol-list.svg';
import { ReactComponent as ProtocolFileIcon } from 'assets/transcript/protocol.svg';
import { ReactComponent as QuestionIcon } from 'assets/transcript/question.svg';
import { ReactComponent as RedoIcon } from 'assets/transcript/redo.svg';
import { ReactComponent as SaveIcon } from 'assets/transcript/save.svg';
import { ReactComponent as TableIcon } from 'assets/transcript/table.svg';
import { ReactComponent as TaskIcon } from 'assets/transcript/task.svg';
import { ReactComponent as TraceIcon } from 'assets/transcript/trace.svg';
import { ReactComponent as UndoIcon } from 'assets/transcript/undo.svg';
import SimpleTranscriptShare from 'components/pages/records/transcript/newControl/transcriptShare/SimpleTranscriptShare';
import TranscriptPlayer from 'components/views/transcriptionsList/transcriptPlayer/TranscriptPlayer';
import { getDateFromTimestamp, getTimeFromTimestamp } from 'helpers/TimeFormatting';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import { setIsEditingAllowed } from 'store/reducers/transcriptions/transcriptAccess';
import {
    changeDemoView,
    decreaseFontSize,
    increaseFontSize,
    toggleChangesVisability,
} from 'store/reducers/transcriptions/transcriptAppearanceSlice';
import { setStoredChanges } from 'store/reducers/transcriptions/transcriptSlice';
import { FileType } from 'types/models';

import './NewTranscriptControl.scss';

const NewTranscriptControl: React.FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [, setSearchParams] = useSearchParams();
    const { transcriptId } = useParams();

    const [getFileInfo] = useLazyGetFileMetadataQuery();
    const [sendEditResult] = useEditResultMutation();

    const { storedChanges, isSaved, transcript } = useAppSelector((state) => state.transcript);
    const { demoView, isChangesVisible } = useAppSelector((state) => state.transcriptAppearance);
    const { isEditingAllowed, shareAccessToken } = useAppSelector((state) => state.transcriptAccess);
    const currentRecord = transcript;

    const [audioInfo, setAudioInfo] = useState<FileType>();
    const [shareModalIsOpen, setShareModalIsOpen] = useState(false);
    const [shareToken, setShareToken] = useState<string | null>(null);
    const [isVisible, setIsVisible] = useState(false);

    const previousVersion =
        transcript.previous_version != null && transcript.version != null ? transcript.previous_version : null;
    const nextVersion = transcript.next_version != null && transcript.version != null ? transcript.next_version : null;

    useEffect(() => {
        if (!currentRecord?.file_id) {
            return;
        }

        getFileInfo({ fileId: currentRecord?.file_id, shareAccessToken })
            .unwrap()
            .then((info) => setAudioInfo(info));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentRecord?.file_id]);

    useEffect(() => {
        const handleScroll = () => {
            const transcriptPage = document.querySelector('.section_left');
            if (transcriptPage) {
                setIsVisible(transcriptPage.scrollTop > 0);
            }
        };

        const transcriptPage = document.querySelector('.section_left');
        if (transcriptPage) {
            transcriptPage.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (transcriptPage) {
                transcriptPage.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);

    const scrollToTop = useCallback(() => {
        const transcriptPage = document.querySelector('.section_left');
        if (transcriptPage) {
            transcriptPage.scrollTop = 0;
        }
    }, []);

    const handleSendEditResult = useCallback(async () => {
        await sendEditResult({
            result: storedChanges,
            req_id: transcriptId ?? '',
            shareAccessToken,
        }).unwrap();
        setSearchParams({});
    }, [sendEditResult, setSearchParams, shareAccessToken, storedChanges, transcriptId]);

    const handleResetChanges = useCallback(async () => {
        dispatch(setStoredChanges(transcript));
        dispatch(resultsApi.util.invalidateTags(['Result']));
    }, [dispatch, transcript]);

    useEffect(() => {
        if (transcript.version === 0) {
            dispatch(setIsEditingAllowed(true));
            return;
        }
        dispatch(setIsEditingAllowed(!isChangesVisible));
    }, [dispatch, isChangesVisible, transcript]);

    return (
        <>
            {!!Object.keys(currentRecord).length && (
                <div className='transcript-control'>
                    <div className='transcript-control__top'>
                        <LogoIcon className='transcript-control__logo' onClick={() => navigate('/uploading')} />
                        <div className='transcript-control__content'>
                            <div>
                                <div className='transcript-control__name-wrapper'>
                                    <TranscriptName />
                                    {!isEditingAllowed ? (
                                        <div className='transcript-control__readonly-label'>Только чтение</div>
                                    ) : null}
                                </div>
                                <span className='transcript-control__upload-date'>
                                    {`Загружено ${getDateFromTimestamp(
                                        currentRecord.timestamp,
                                    )} в ${getTimeFromTimestamp(currentRecord.timestamp)}`}
                                </span>
                                <button
                                    className='btn btn_transparent btn_with_icon transcript-control__share'
                                    onClick={(e) => {
                                        if (!isEditingAllowed) {
                                            return;
                                        }
                                        setShareModalIsOpen(true);
                                    }}
                                >
                                    <span>Поделиться</span>
                                    <TraceIcon />
                                </button>
                            </div>
                            <div>
                                <TranscriptPlayer
                                    audioUrl={audioInfo?.stream_url || ''}
                                    duration={audioInfo?.duration || 0}
                                />
                                <TranscriptControlMenu />
                            </div>
                        </div>
                        <div
                            className='transcript-control__close'
                            onClick={() => {
                                navigate('/archive');
                            }}
                        >
                            <CloseIcon />
                        </div>
                    </div>
                    <div className='transcript-control__bottom'>
                        <div className='transcript-control__actions'>
                            <div className='transcript-control__actions-group'>
                                <div
                                    className={`btn btn_with_icon tooltip ${
                                        previousVersion == null ? 'btn-disabled' : ''
                                    }`}
                                    onClick={() => {
                                        if (previousVersion != null) {
                                            setSearchParams({ version: previousVersion.toString() });
                                        }
                                    }}
                                >
                                    <UndoIcon />
                                    <span className='tooltip_text'>Предыдущая версия</span>
                                </div>
                                <div
                                    className={`btn btn_with_icon tooltip ${nextVersion == null ? 'btn-disabled' : ''}`}
                                    onClick={() => {
                                        if (nextVersion != null) {
                                            setSearchParams({ version: nextVersion.toString() });
                                        }
                                    }}
                                >
                                    <RedoIcon />
                                    <span className='tooltip_text'>Следующая версия</span>
                                </div>
                            </div>
                            <div className='transcript-control__actions-group'>
                                <div
                                    className={`btn btn_with_icon tooltip ${!isChangesVisible ? 'btn-inactive' : ''} ${
                                        transcript.version === 0 || previousVersion == null ? 'btn-disabled' : ''
                                    }`}
                                    onClick={() => {
                                        if (transcript.version !== 0) {
                                            dispatch(toggleChangesVisability());
                                        }
                                    }}
                                >
                                    <CompareIcon />
                                    <span className='tooltip_text'>
                                        {isChangesVisible ? 'Скрыть изменения' : 'Показать изменения'}
                                    </span>
                                </div>
                                <div className={`btn btn_with_icon tooltip`} onClick={handleResetChanges}>
                                    <BackArrowIcon />
                                    <span className='tooltip_text'>Отменить изменения</span>
                                </div>
                                <div className={`btn btn_with_icon tooltip`} onClick={handleSendEditResult}>
                                    <SaveIcon />
                                    <span className='tooltip_text'>Сохранить изменения</span>
                                </div>
                            </div>
                            <div className='transcript-control__actions-group'>
                                <div
                                    className='btn btn_with_icon'
                                    onClick={() => {
                                        dispatch(increaseFontSize());
                                    }}
                                >
                                    <IncreaseFont />
                                </div>
                                <div
                                    className='btn btn_with_icon'
                                    onClick={() => {
                                        dispatch(decreaseFontSize());
                                    }}
                                >
                                    <DecreaseFont />
                                </div>
                            </div>
                            {/* закомментировано до появления соответствующего функционала */}
                            {/* <div className='actions_group'>
                                <div className='btn btn_with_icon'>
                                    <InexactIcon />
                                </div>
                                <div className='clean btn btn_with_icon'>
                                    <CleanIcon />
                                </div>
                                </div> */}
                            {transcript.version_timestamp != null ? (
                                <span className='transcript-control__saving-date'>
                                    {isSaved
                                        ? `Сохранено ${getDateFromTimestamp(transcript.version_timestamp)}
                                    в ${getTimeFromTimestamp(transcript.version_timestamp)}`
                                        : 'Сохранение...'}
                                </span>
                            ) : null}
                            <div style={{ flex: 1 }}></div>
                            <div
                                className={`transcript-control__protocol btn btn_with_icon ${
                                    demoView === 'brief' ? 'btn_secondary' : ''
                                }`}
                                onClick={() => {
                                    if (!isEditingAllowed) {
                                        return;
                                    }
                                    dispatch(changeDemoView('brief'));
                                }}
                            >
                                <ProtocolIcon />
                                <span>Brief</span>
                            </div>
                            <div
                                className={`transcript-control__protocol btn btn_with_icon ${
                                    demoView === 'topics' ? 'btn_secondary' : ''
                                }`}
                                onClick={() => {
                                    if (!isEditingAllowed) {
                                        return;
                                    }
                                    dispatch(changeDemoView('topics'));
                                }}
                            >
                                <TaskIcon />
                                <span>Topics</span>
                            </div>
                            <div
                                className={`transcript-control__protocol btn btn_with_icon ${
                                    demoView === 'tasks' ? 'btn_secondary' : ''
                                }`}
                                onClick={() => {
                                    if (!isEditingAllowed) {
                                        return;
                                    }
                                    dispatch(changeDemoView('tasks'));
                                }}
                            >
                                <QuestionIcon />
                                <span>Tasks</span>
                            </div>

                            {/* Закомментировано для показа*/}
                            {/* <div
                                className={`transcript-control__protocol btn btn_with_icon ${
                                    demoView === 'highlights' ? 'btn_secondary' : ''
                                }`}
                                onClick={() => {
                                    if (!isEditingAllowed) {
                                        return;
                                    }
                                    dispatch(changeDemoView('highlights'));
                                }}
                            >
                                <HighlightIcon />
                                <span>Highlights</span>
                            </div>
                            <div
                                className={`transcript-control__protocol btn btn_with_icon ${
                                    demoView === 'projects' ? 'btn_secondary' : ''
                                }`}
                                onClick={() => {
                                    if (!isEditingAllowed) {
                                        return;
                                    }
                                    dispatch(changeDemoView('projects'));
                                }}
                            >
                                <ProjectsIcon />
                                <span>Projects</span>
                            </div> */}
                            <div
                                className={`transcript-control__protocol btn btn_with_icon ${
                                    demoView === 'tables' ? 'btn_secondary' : ''
                                }`}
                                onClick={() => {
                                    if (!isEditingAllowed) {
                                        return;
                                    }
                                    dispatch(changeDemoView('tables'));
                                }}
                            >
                                <TableIcon />
                                <span>Tables</span>
                            </div>
                            <div
                                className={`transcript-control__protocol btn btn_with_icon ${
                                    demoView === 'protocol' ? 'btn_secondary' : ''
                                }`}
                                onClick={() => {
                                    if (!isEditingAllowed) {
                                        return;
                                    }
                                    dispatch(changeDemoView('protocol'));
                                }}
                            >
                                <ProtocolFileIcon />
                                <span>Protocol</span>
                            </div>
                        </div>
                        <div
                            className={`transcript-control__up ${isVisible ? 'visible' : 'hidden'}`}
                            onClick={scrollToTop}
                        >
                            <ArrowIcon />
                        </div>
                    </div>
                </div>
            )}
            {shareModalIsOpen && (
                <SimpleTranscriptShare
                    transcriptId={transcript.session_id}
                    fileId={transcript.file_id}
                    hideModal={() => setShareModalIsOpen(false)}
                    savedShareToken={shareToken}
                    setSavedShareToken={(token) => setShareToken(token)}
                />
            )}
        </>
    );
};

export default NewTranscriptControl;
