import { createApi } from '@reduxjs/toolkit/query/react';

import { customBaseQuery } from 'api/query';
import { CommentPayload } from 'api/types/controllers';
import { CommentType } from 'types/models';

const API_API_HOST = process.env.REACT_APP_API_API_HOST;

export const commentsApi = createApi({
    reducerPath: 'commentsApi',
    baseQuery: customBaseQuery({ baseUrl: `${API_API_HOST}/api/` }),
    endpoints: (build) => {
        return {
            getComments: build.query<CommentType[], string>({
                query: (req_id) => ({
                    url: `result/${req_id}/comments/`,
                    method: 'GET',
                }),
            }),
            addComment: build.mutation<void, { id: string; comment: CommentPayload }>({
                query: ({ id, comment }) => ({
                    url: `result/${id}/comments/`,
                    method: 'POST',
                    body: comment,
                }),
            }),
        };
    },
});

export const { useGetCommentsQuery, useAddCommentMutation } = commentsApi;
