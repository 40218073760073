import { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Bars } from 'react-loader-spinner';
import { toast } from 'react-toastify';

import { UploadedFile } from './uploadedFile/UploadedFile';

import { useRecognizeFileMutation } from 'api/routes/RequestsApi';
import { TranscriptInfoFormProps } from 'components/views/recordUploader/UploadingPage.interface';

import './TranscriptInfoForm.scss';

import { LS } from 'config/env';
import { TRANSCRIPT_NAME_LENGTH_MAX_LIMIT } from 'constants/common';
import { getTimeFromTimestamp, getYMDDateFromTimestamp } from 'helpers/TimeFormatting';

const TranscriptInfoForm: React.FC<TranscriptInfoFormProps> = ({
    navigateToNextStep,
    handleCancelUpload,
    clearAllUploadedFiles,
    uploadedFiles,
}): JSX.Element => {
    const [sendToRecognize, { isLoading: recognizeLoading }] = useRecognizeFileMutation();
    const isNeedToStore = useRef(true);

    const initDefaultValues = () => {
        const values: { [key: string]: any } = {};

        uploadedFiles.forEach((el) => {
            values[el.id] = {};
            values[el.id][`user_path`] = `${getYMDDateFromTimestamp(Date.now())} ${getTimeFromTimestamp(
                Date.now(),
                true,
            )} ${el.name}`.slice(0, TRANSCRIPT_NAME_LENGTH_MAX_LIMIT);
            values[el.id][`enable_annotation`] = true;
            // values[el.id][`unknownSpeakersNumber`] = true;
        });

        return values;
    };

    const methods = useForm({
        mode: 'onChange',
        defaultValues: localStorage.getItem(LS.INFO_FORM)
            ? JSON.parse(localStorage.getItem(LS.INFO_FORM)!)
            : initDefaultValues(),
    });

    const {
        handleSubmit,
        getValues,
        // control,
    } = methods;

    const onReturnToBeginningClick = () => {
        isNeedToStore.current = false;
        handleCancelUpload();
    };

    useEffect(() => {
        return () => {
            if (isNeedToStore.current) {
                localStorage.setItem(LS.INFO_FORM, JSON.stringify(getValues()));
            } else {
                localStorage.removeItem(LS.INFO_FORM);
            }
        };
    }, [getValues]);

    return (
        <FormProvider {...methods}>
            <form
                onSubmit={handleSubmit((transcriptData) => {
                    Promise.all(
                        Object.entries(transcriptData).map(([key, value]) =>
                            sendToRecognize({ ...(value as object), fileId: key }).unwrap(),
                        ),
                    )
                        .then(() => {
                            toast.success('Расшифровка запущена');
                            navigateToNextStep();
                            clearAllUploadedFiles();
                            isNeedToStore.current = false;
                        })
                        .catch((error) => {
                            if (error.data?.code === 402) {
                                toast.error('Недостаточно средств на счете. Пополните баланс');
                            } else {
                                toast.error(
                                    'Что-то пошло не так. Пожалуйста, попробуйте снова позже или выберете другой файл',
                                );
                            }
                        });
                })}
                className='transcription_details_form'
            >
                {uploadedFiles
                    .sort((el1, el2) => Number(el2.is_already_transcribed) - Number(el1.is_already_transcribed))
                    .map((el) => (
                        <UploadedFile key={el.id} file={el} formMethods={methods} />
                    ))}

                {uploadedFiles.some((el) => !el.is_already_transcribed && !el.is_transcribing) && (
                    <button className='btn btn_primary' type='submit'>
                        {recognizeLoading ? (
                            <Bars
                                height='25'
                                width='30'
                                color='white'
                                ariaLabel='bars-loading'
                                wrapperStyle={{}}
                                wrapperClass=''
                            />
                        ) : (
                            'Начать расшифровку'
                        )}
                    </button>
                )}
                <button className='btn btn_secondary' type='button' onClick={onReturnToBeginningClick}>
                    {false ? (
                        <Bars
                            height='25'
                            width='30'
                            color='white'
                            ariaLabel='bars-loading'
                            wrapperStyle={{}}
                            wrapperClass=''
                        />
                    ) : (
                        'Вернуться в начало'
                    )}
                </button>
            </form>
        </FormProvider>
    );
};

export default TranscriptInfoForm;
