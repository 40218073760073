import { useCallback, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

import { useGetFilesQuery, useRenameFileMutation } from 'api/routes/RecordsApi';
import { ReactComponent as PenIcon } from 'assets/pen.svg';
import { TRANSCRIPT_NAME_LENGTH_MAX_LIMIT } from 'constants/common';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import { setTranscriptName } from 'store/reducers/transcriptions/transcriptSlice';

import './TranscriptName.scss';

const TranscriptName: React.FC = () => {
    const dispatch = useAppDispatch();
    const [renameFile] = useRenameFileMutation();

    const { transcript, editedTranscript } = useAppSelector((state) => state.transcript);
    const { isEditingAllowed } = useAppSelector((state) => state.transcriptAccess);
    const { data: files } = useGetFilesQuery(null);

    const getRecordName = useCallback(() => {
        const currentFile = files?.results?.find((el) => el.file_id === transcript?.file_id);
        return currentFile ? currentFile.path : '';
    }, [files?.results, transcript?.file_id]);

    const currentTranscriptName = useMemo(
        () => editedTranscript?.user_path || getRecordName(),
        [editedTranscript?.user_path, getRecordName],
    );

    const [isEditableName, setIsEditableName] = useState(false);
    const [newValue, setNewValue] = useState(currentTranscriptName);

    const handleChangeName = useCallback(async () => {
        if (newValue) {
            if (newValue === currentTranscriptName) {
                return;
            }

            await renameFile({
                file_id: transcript.file_id,
                user_path: newValue,
            })
                .unwrap()
                .then(() => {
                    dispatch(setTranscriptName({ name: newValue }));
                })
                .catch((error) => {
                    if (error.status === 406) {
                        toast.error('Данное название уже занято');
                    }
                })
                .finally(() => {
                    setNewValue(currentTranscriptName);
                });
        }
    }, [currentTranscriptName, dispatch, newValue, renameFile, transcript.file_id]);

    const handleCloseEditor = useCallback(() => {
        setIsEditableName(false);
        setNewValue(currentTranscriptName);
    }, [currentTranscriptName]);

    const handleOpenEditor = useCallback(() => {
        setIsEditableName(true);
        setNewValue(currentTranscriptName);
    }, [currentTranscriptName]);

    return (
        <div
            className={`transcript-name__container ${
                isEditingAllowed && isEditableName ? 'transcript-name__editable' : ''
            }`}
        >
            <div className='transcript-name__name-container'>
                {isEditingAllowed && isEditableName ? (
                    <input
                        className='transcript-name__input'
                        value={newValue}
                        onChange={(e) => setNewValue(e.target.value)}
                        maxLength={TRANSCRIPT_NAME_LENGTH_MAX_LIMIT}
                    />
                ) : (
                    <div className='transcript-name__content'>{currentTranscriptName}</div>
                )}
                <button className='transcript-name__edit-btn btn btn_with_icon' onClick={handleOpenEditor}>
                    <PenIcon />
                </button>
            </div>
            <div className='transcript-name__btn-container'>
                <button className='transcript-name__cancel-btn btn btn_with_icon' onClick={handleCloseEditor}>
                    Отменить
                </button>
                <button
                    className='transcript-name__save-btn btn btn_with_icon'
                    onClick={() => handleChangeName().then(handleCloseEditor)}
                >
                    Сохранить
                </button>
            </div>
        </div>
    );
};

export default TranscriptName;
