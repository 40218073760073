import { LS } from 'config/env';

export const getJWTToken = () => localStorage.getItem(LS.JWT_TOKEN);

export const getRefreshToken = () => localStorage.getItem(LS.REFRESH_TOKEN);

export const getAPIToken = () => localStorage.getItem(LS.API_TOKEN);

export const getShareAccessInfo = () => localStorage.getItem(LS.SHARE_INFO);

export const getTranscriptFontSize = () => localStorage.getItem(LS.TRANSCRIPT_FONT_SIZE);

export const setJWTToken = (token: string) => localStorage.setItem(LS.JWT_TOKEN, token);

export const setRefreshToken = (token: string) => localStorage.setItem(LS.REFRESH_TOKEN, token);

export const setAPIToken = (token: string) => localStorage.setItem(LS.API_TOKEN, token);

export const setShareAccessInfo = (info: string) => localStorage.setItem(LS.SHARE_INFO, info);

export const setTranscriptFontSize = (size: string) => localStorage.setItem(LS.TRANSCRIPT_FONT_SIZE, size);

export const removeTranscriptFontSize = () => localStorage.removeItem(LS.TRANSCRIPT_FONT_SIZE);

export const removeAllFromLS = () => {
    Object.values(LS).forEach((value) => localStorage.removeItem(value));
};

export const checkIfTokensSaved = () =>
    localStorage.hasOwnProperty(LS.API_TOKEN) && localStorage.hasOwnProperty(LS.JWT_TOKEN);
