export const convertHtmlToPlainText = (html: string): string => {
    const tempElement = document.createElement('div');
    tempElement.innerHTML = html;

    return tempElement.innerHTML
        .replace(/<\/p>/g, '\n') // <p> = перенос строки
        .replace(/<br\s*\/?>/g, '\n') // <br> = перенос строки
        .replace(/<[^>]+>/g, '')
        .trim();
};
