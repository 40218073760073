import { useCallback, useEffect, useRef, useState } from 'react';

import { toast } from 'react-toastify';

import ChooseDictionaryModal from './ChooseDictionaryModal';

import { TranscriptControlPanelProps } from '../Transcript.interface';

import { useAddDictionaryWordMutation } from 'api/routes/DictionaryApi';
import { NewDictionaryWordPayload } from 'api/types/controllers';
import { ReactComponent as ManIcon } from 'assets/man.svg';
import { ReactComponent as CommentIcon } from 'assets/transcript/comment.svg';
import { ReactComponent as DictIcon } from 'assets/transcript/dict.svg';
import FormattedTime from 'components/generic/formattedTime/FormattedTime';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import useOutsideClick from 'hooks/useOutsideClick';
import { setIsCreated } from 'store/reducers/comments/commentsSlice';
import { setAudioFragmentPlaying, playSelectedTimestepRange } from 'store/reducers/transcriptions/audioSlice';
import { setCreateCommentFlag } from 'store/reducers/transcriptions/controlSlice';

import './TranscriptControlPanel.scss';

const TranscriptControlPanel: React.FC<TranscriptControlPanelProps> = ({
    isAudioControlDisplay,
    setIsAudioControlDisplay,
}): JSX.Element => {
    const dispatch = useAppDispatch();
    const { isFragmentPlaying, selectedTimestepRange, selectedPosition } = useAppSelector((state) => state.audio);
    const { selectedReplicaId, selectedWordsText } = useAppSelector((state) => state.control);
    const { isEditingAllowed } = useAppSelector((state) => state.transcriptAccess);

    const [addDictionaryWord] = useAddDictionaryWordMutation();

    const [isPlaying, setIsPlaying] = useState<boolean>(false);
    const isMounted = useRef<boolean>(false);

    const refer = useRef<HTMLDivElement>(null);

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const toggle = () => {
        setIsPlaying(!isPlaying);
        dispatch(setAudioFragmentPlaying(!isPlaying));
        dispatch(playSelectedTimestepRange());
    };

    const createComment = () => {
        dispatch(setIsCreated(true));
    };

    const addToDictionary = useCallback(
        (dicId: number, word: string) => {
            const addingWord: NewDictionaryWordPayload = {
                dict: dicId,
                word: word,
            };
            addDictionaryWord(addingWord)
                .unwrap()
                .then(() => {
                    toast.success(`Слово ${word} добавлено в словарь`, { position: 'bottom-right' });
                })
                .catch(() => toast.error(`Слово ${word} уже есть в словаре`, { position: 'bottom-right' }));
        },
        [addDictionaryWord],
    );

    const createReplica = useCallback(() => {
        if (selectedReplicaId) {
            dispatch(setCreateCommentFlag(true));
        }
    }, [dispatch, selectedReplicaId]);

    useEffect(() => {
        if (isMounted.current) {
            setIsAudioControlDisplay(true);
            setIsPlaying(false);
        } else {
            isMounted.current = true;
        }
    }, [selectedPosition, setIsAudioControlDisplay]);

    useEffect(() => {
        if (!isFragmentPlaying) {
            setIsPlaying(false);
        }
    }, [isFragmentPlaying]);

    useOutsideClick([refer], () => {
        setIsAudioControlDisplay(false);
    });

    return (
        <>
            <div
                ref={refer}
                className='control_panel'
                style={{
                    display: isAudioControlDisplay ? '' : 'none',
                    left: selectedPosition[0] || 10000,
                    top: selectedPosition[1] || 10000,
                }}
            >
                <div className='play_button_container' onClick={toggle}>
                    <div className={isPlaying ? 'play_button--pause' : 'play_button--play'}></div>
                </div>
                <div className='timestamp'>
                    {selectedTimestepRange[0] && <FormattedTime duration={selectedTimestepRange[0]} isSec={true} />}
                </div>
                {isEditingAllowed && (
                    <>
                        <div className='tooltip'>
                            <CommentIcon className='icon' onClick={createComment} />
                            <span className='tooltiptext'>Добавить комментарий</span>
                        </div>
                        <div className='tooltip'>
                            <ManIcon className={`icon ${!selectedReplicaId && 'disabled'}`} onClick={createReplica} />
                            <span className='tooltiptext'>Добавить реплику</span>
                        </div>
                        <div className='tooltip'>
                            <DictIcon
                                className={`icon ${!selectedWordsText && 'disabled'}`}
                                onClick={() => {
                                    if (selectedWordsText) {
                                        setIsModalOpen(true);
                                    }
                                }}
                            />
                            <span className='tooltiptext'>Добавить в словарь</span>
                        </div>
                    </>
                )}
            </div>
            {isModalOpen && (
                <ChooseDictionaryModal
                    setModalIsOpen={setIsModalOpen}
                    handleAddToDictionary={addToDictionary}
                    addedWord={selectedWordsText}
                />
            )}
        </>
    );
};

export default TranscriptControlPanel;
