import classNames from 'classnames';
import React, { useCallback, useState } from 'react';

import { CalendarProps, RangeSelectorType } from './Calendar.interfaces';

import CalendarCells from './calendarCells/CalendarCells';
import CalendarHeader from './calendarHeader/CalendarHeader';
import CalendarNavigation from './calendarNavigation/CalendarNavigation';
import WeekdaysBar from './weekdaysBar/WeekdaysBar';

import { CALENDAR_MODES } from 'constants/common';

import './Calendar.scss';

const Calendar: React.FC<CalendarProps> = (props): JSX.Element => {
    const [view, changeView] = useState<boolean>(true);

    const [currentMode, changeCurrentMode] = useState<string>(CALENDAR_MODES.date);

    const [selectedDate, changeSelectedDate] = useState<Date>(new Date());
    const [searchedDate, changeSearchedDate] = useState<Date | null>(null);

    const [selectedPeriod, changeSelectedPeriod] = useState<Date[]>([]);
    const [rangeSelector, changeRangeOfDates] = useState<RangeSelectorType>({
        startOfOPeriod: new Date(),
        endOfOPeriod: new Date(),
        waiting: false,
        focus: false,
    });

    const setSelectedDate = useCallback((date: Date) => {
        changeSelectedDate(date);
    }, []);

    const setSearchDate = useCallback(
        (date: Date | null) => {
            changeSearchedDate(date);
            props.setSearchDate(date);
        },
        [props],
    );

    const setSelectedDateRange = useCallback(
        (startDate: Date, endDate: Date) => {
            props.setSearchDateRange([startDate, endDate]);
        },
        [props],
    );

    const resetSelectedDate = useCallback(() => {
        props.setSearchDateRange(null);
        props.setSearchDate(null);
        changeSearchedDate(null);
        changeSelectedPeriod([]);
        changeRangeOfDates({
            startOfOPeriod: new Date(),
            endOfOPeriod: new Date(),
            waiting: false,
            focus: false,
        });
    }, [props]);

    return (
        <div className='calendar'>
            <CalendarHeader
                selectedDate={selectedDate}
                searchedDate={searchedDate}
                view={view}
                changeView={changeView}
                currentMode={currentMode}
                rangeSelector={rangeSelector}
                resetSelectedDate={resetSelectedDate}
            />
            <div className={classNames('calendar_body', { 'calendar_body--open': view })}>
                <hr></hr>
                <CalendarNavigation
                    selectedDate={selectedDate}
                    changeSelectedDate={changeSelectedDate}
                    setSelectedDate={setSelectedDate}
                    setSearchDate={setSearchDate}
                    currentMode={currentMode}
                    changeCurrentMode={changeCurrentMode}
                    changeSelectedPeriod={changeSelectedPeriod}
                />
                <div className='calendar_content'>
                    <WeekdaysBar />
                    <CalendarCells
                        selectedDate={selectedDate}
                        setSelectedDate={setSelectedDate}
                        searchedDate={searchedDate}
                        setSearchDate={setSearchDate}
                        currentMode={currentMode}
                        selectedPeriod={selectedPeriod}
                        changeSelectedPeriod={changeSelectedPeriod}
                        rangeSelector={rangeSelector}
                        changeRangeOfDates={changeRangeOfDates}
                        setSelectedDateRange={setSelectedDateRange}
                    />
                </div>
            </div>
        </div>
    );
};

export default Calendar;
