import { BaseQueryFn, FetchArgs, FetchBaseQueryError, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { getAPIToken, getRefreshToken, setJWTToken } from 'helpers/LocalStorageActions';
import { logout } from 'helpers/LogoutActions';

interface CustomBaseQuery {
    baseUrl: string;
    requiresAuth?: boolean;
    enableReauth?: boolean;
}

type RefetchResultSchema = {
    data: { access: string };
};

export const customBaseQuery = ({
    baseUrl,
    requiresAuth = true,
    enableReauth = false,
}: CustomBaseQuery): BaseQueryFn<FetchArgs, unknown, FetchBaseQueryError> => {
    const baseQuery = fetchBaseQuery({
        baseUrl,
        prepareHeaders: (headers) => {
            if (requiresAuth) {
                const token = getAPIToken();
                if (token && !headers.has('authorization')) {
                    headers.set('authorization', `Bearer ${token}`);
                }
            }
            return headers;
        },
    });

    return async (args, api, extraOptions) => {
        let result = await baseQuery(args, api, extraOptions);
        if (
            enableReauth &&
            result.error &&
            (result.error.status === 401 || result.error.status === 'FETCH_ERROR') &&
            args.url !== 'login/' &&
            args.url !== 'password_reset/' &&
            args.url !== 'password_change/' &&
            args.url !== 'register/'
        ) {
            const refreshResult = (await baseQuery(
                { url: 'refresh_token/', method: 'POST', body: { refresh: getRefreshToken() } },
                api,
                extraOptions,
            )) as RefetchResultSchema;
            if (refreshResult.data) {
                setJWTToken(refreshResult.data.access);
                result = await baseQuery(
                    {
                        ...args,
                        headers: {
                            ...args.headers,
                            Authorization: `Bearer ${refreshResult.data.access}`,
                        },
                    },
                    api,
                    extraOptions,
                );
            } else {
                logout(api.dispatch);
            }
        }
        return result;
    };
};
