import { useEffect } from 'react';
import { RotatingLines } from 'react-loader-spinner';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useLazyExchangeShareTokenQuery } from 'api/routes/AppApi';
import { ExchangeShareTokenReponse } from 'api/types/controllers';
import { getShareAccessInfo, removeAllFromLS, setShareAccessInfo } from 'helpers/LocalStorageActions';

import './ShareRedirection.scss';
import { useAppSelector } from 'hooks/useAppSelector';

const ShareRedirection: React.FC = () => {
    const navigate = useNavigate();

    const { token } = useParams();
    const { pathname } = useLocation();

    const [exchangeToken, { isLoading, isError }] = useLazyExchangeShareTokenQuery();
    const { isAuthorized } = useAppSelector((state) => state.user);

    useEffect(() => {
        if (!isAuthorized) {
            navigate('/login', { state: pathname });
            removeAllFromLS();
            return;
        }

        exchangeToken(token || '')
            .unwrap()
            .then((shareData) => {
                let newSharedInfo = shareData;

                const sharedInfo = getShareAccessInfo();
                if (sharedInfo) {
                    const savedSharedInfo: ExchangeShareTokenReponse[] = JSON.parse(sharedInfo);

                    newSharedInfo.forEach((sharedObject) => {
                        const targetTranscript = savedSharedInfo.findIndex(
                            (item) => item.object === sharedObject.object,
                        );

                        if (targetTranscript !== -1) {
                            savedSharedInfo.splice(targetTranscript, 1);
                        }
                    });
                    newSharedInfo = [...newSharedInfo, ...savedSharedInfo];
                }

                setShareAccessInfo(JSON.stringify(newSharedInfo));
                navigate(`/archive/transcript/${shareData.find((obj) => obj.unit === 'api')?.object}`);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthorized]);

    return (
        <div className='share-redirection'>
            {isLoading && <RotatingLines strokeColor='#814feb' strokeWidth='5' animationDuration='0.75' width='30' />}
            {isError && <p>Ошибка</p>}
        </div>
    );
};

export default ShareRedirection;
