import { useEffect } from 'react';
import { toast } from 'react-toastify';

import { useLazyDownloadProtocolByIdQuery } from 'api/routes/RecordsApi';
import { ReactComponent as DownloadIcon } from 'assets/transcript/download.svg';
import './DemoProtocolViewer.scss';
import { useAppSelector } from 'hooks/useAppSelector';
export const DemoProtocolViewer = () => {
    const { transcript } = useAppSelector((state) => state.transcript);

    const [downloadProtocol, { error: isDownloadError }] = useLazyDownloadProtocolByIdQuery();

    useEffect(() => {
        if (isDownloadError) {
            toast.error('Не удалось скачать файл');
        }
    }, [isDownloadError]);

    return (
        <div className={'demo-protocol-viewer__container'}>
            <button
                className='btn btn_transparent btn_with_icon'
                onClick={() => downloadProtocol({ fileId: transcript.file_id, name: transcript.user_path })}
            >
                <span>Скачать протокол</span>
                <DownloadIcon />
            </button>
        </div>
    );
};
