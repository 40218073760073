import { ReactComponent as CrossIcon } from 'assets/cross.svg';

import './CrossButton.scss';

interface CrossButtonProps {
    handleClick: () => void;
}

const CrossButton: React.FC<CrossButtonProps> = ({ handleClick }) => (
    <CrossIcon onClick={handleClick} className='cross' />
);

export default CrossButton;
