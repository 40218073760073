import { useEffect, useRef, useState, useCallback } from 'react';

// import { ReactComponent as Arrow } from 'assets/arrow-with-body.svg';
import SearchBar from 'components/generic/searchBar/SearchBar';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import { resetSearchWords, searchWords } from 'store/reducers/transcriptions/transcriptSlice';

import './TranscriptSearch.scss';

const TranscriptSearch: React.FC = () => {
    const dispatch = useAppDispatch();

    const { archiveFilterWord: filterWord } = useAppSelector((state) => state.transcript);
    const { editedTranscript, isFiltered, isEmptyFilterResult, foundedWords } = useAppSelector(
        (state) => state.transcript,
    );

    const [searchText, setSearchText] = useState(filterWord || '');

    const currentWord = useRef<{ replicaId: string; wordIdx: number } | null>(null);
    const isMounted = useRef<boolean>(false);

    const resetCurrentWordInfo = useCallback(() => {
        const prevElement = document.querySelector('.replica__word--viewed');
        if (prevElement) {
            prevElement.classList.remove('replica__word--viewed');
        }

        currentWord.current = null;
    }, []);

    useEffect(() => {
        if (editedTranscript.result?.length && filterWord) {
            dispatch(searchWords(filterWord));
        }
    }, [dispatch, editedTranscript.result?.length, filterWord]);

    useEffect(() => {
        if (isMounted.current) {
            if (!searchText.length) {
                resetCurrentWordInfo();
                dispatch(resetSearchWords());
                return;
            }
            const delayDebounceFn = setTimeout(() => {
                resetCurrentWordInfo();
                dispatch(searchWords(searchText));
            }, 500);

            return () => clearTimeout(delayDebounceFn);
        } else {
            isMounted.current = true;
        }
    }, [dispatch, resetCurrentWordInfo, searchText]);

    const moveToWord = useCallback(() => {
        if (!isFiltered || isEmptyFilterResult) {
            return;
        }

        let nextWord;
        let nextReplica;

        if (currentWord.current) {
            const words = foundedWords[currentWord.current.replicaId];
            if (words && words.indexOf(currentWord.current.wordIdx) !== words.length - 1) {
                let wordIdx = words.indexOf(currentWord.current.wordIdx) + 1;
                nextReplica = currentWord.current.replicaId;
                nextWord = words[wordIdx];
            } else {
                let keys = Object.keys(foundedWords);
                let nextIndex = keys.indexOf(currentWord.current.replicaId) + 1;
                nextReplica = keys[nextIndex];
                if (nextReplica) {
                    nextWord = foundedWords[nextReplica][0];
                }
            }
        }

        if (!currentWord.current || nextReplica == null) {
            let keys = Object.keys(foundedWords);
            nextReplica = keys[0];
            nextWord = foundedWords[nextReplica][0];
        }

        if (nextReplica != null && nextWord != null) {
            const prevElement = document.getElementById(
                `founded_${currentWord.current?.replicaId}-${currentWord.current?.wordIdx}`,
            );
            if (prevElement) {
                prevElement.classList.remove('replica__word--viewed');
            }

            const element = document.getElementById(`founded_${nextReplica}-${nextWord}`);
            if (element) {
                element.classList.add('replica__word--viewed');

                element.scrollIntoView({
                    block: 'center',
                    behavior: 'smooth',
                });
            }

            currentWord.current = { replicaId: nextReplica, wordIdx: nextWord };
        }
    }, [foundedWords, isEmptyFilterResult, isFiltered]);

    const handlePressEnter = useCallback(
        (text: string) => {
            if (isFiltered) {
                moveToWord();
                return;
            }

            resetCurrentWordInfo();
            if (!searchText.length) {
                dispatch(resetSearchWords());
            } else {
                dispatch(searchWords(text));
            }
        },
        [dispatch, isFiltered, moveToWord, resetCurrentWordInfo, searchText.length],
    );

    return (
        <div className={`transcript_search ${isFiltered && isEmptyFilterResult ? 'not_found' : ''}`}>
            <SearchBar
                defaultText='Искать в записи'
                searchText={searchText}
                setSearchText={setSearchText}
                onPressEnter={handlePressEnter}
            />
            {/* <div
                className={`founded_words_arrow btn btn_with_icon ${
                    isFiltered && !isEmptyFilterResult && 'btn-disabled'
                }`}
                onClick={moveToWord}
            >
                <Arrow />
            </div> */}
        </div>
    );
};

export default TranscriptSearch;
