import ContactUs from './contactUs/ContactUs';
import FAQ from './faq/FAQ';
import Features from './features/Features';
import Greeting from './greeting/Greeting';

import './Landing.scss';

const Landing: React.FC = () => (
    <div className='main_landing'>
        <Greeting />
        <Features />
        <FAQ />
        <ContactUs />
    </div>
);

export default Landing;
