import classNames from 'classnames';
import { useState } from 'react';
import { Link, Route, Routes } from 'react-router-dom';

import arrowLeft from 'assets/menu/arrow-left.svg';
import audioList from 'assets/menu/audio_list.svg';
import { ReactComponent as CloseIcon } from 'assets/menu/close.svg';
import profile from 'assets/menu/profile.svg';
import LoginForm from 'components/pages/auth/login/LoginForm';
import RegisterForm from 'components/pages/auth/register/RegisterForm';
import ResetPasswordForm from 'components/pages/auth/resetPassword/ResetPasswordForm';
import { logout } from 'helpers/LogoutActions';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import './Menu.scss';

const Menu: React.FC = () => {
    const [menuIsOpen, setMenuOpen] = useState(false);

    const { isAuthorized } = useAppSelector((state) => state.user);

    const dispatch = useAppDispatch();

    return (
        <div className={classNames('menu menu_background', { 'menu--open': menuIsOpen })}>
            {!isAuthorized && !menuIsOpen && (
                <div className='menu-nav'>
                    <Link to='/login' className='menu-nav__item' onClick={() => setMenuOpen(true)}>
                        <img src={arrowLeft} className='arrow-left' alt='arrow-left' />
                        <img className='menu-nav__item-icon' src={profile} alt='profile' />
                        <span className='menu-nav__item-text'>Войти</span>
                    </Link>
                </div>
            )}
            {!isAuthorized && menuIsOpen && (
                <div className='authorization_container'>
                    <div className='content'>
                        <Routes>
                            <Route path='login' element={<LoginForm closeMenu={() => setMenuOpen(false)} />} />
                            <Route path='register' element={<RegisterForm />} />
                            <Route path='forgot-password' element={<ResetPasswordForm />} />
                        </Routes>
                    </div>
                    <Link to='/' className='close_button' onClick={() => setMenuOpen(false)}>
                        <CloseIcon className='icon' />
                        <span className='text'>закрыть</span>
                    </Link>
                </div>
            )}
            {isAuthorized && (
                <div className='menu-nav'>
                    <Link to='archive' className='menu-nav__item'>
                        <img src={arrowLeft} className='arrow-left' alt='arrow-left' />
                        <img className='menu-nav__item-icon' src={audioList} alt='profile' />
                        <span className='menu-nav__item-text'>Записи</span>
                    </Link>
                    <Link to='profile/info' className='menu-nav__item'>
                        <img src={arrowLeft} className='arrow-left' alt='arrow-left' />
                        <img className='menu-nav__item-icon' src={profile} alt='profile' />
                        <span className='menu-nav__item-text'>Мой аккаунт</span>
                    </Link>
                    <div className='menu-nav__item' onClick={() => logout(dispatch)}>
                        <img src={arrowLeft} className='arrow-left' alt='arrow-left' />
                        <img className='menu-nav__item-icon' src={profile} alt='profile' />
                        <span className='menu-nav__item-text'>Выйти</span>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Menu;
