import { useCallback, useEffect, useState } from 'react';
import { RotatingLines } from 'react-loader-spinner';

import { toast } from 'react-toastify';

import { CompletedTranscriptPayload, CompletedSearchTranscriptPayload } from './RecordsList.interface';
import RequestItem from './requestItem/RequestItem';

// TODO: TAS-1007
// import { useGetUserBalanceInfoQuery } from 'api/routes/AppApi';
import { useGetFilesByIdsMutation } from 'api/routes/RecordsApi';
import { useRequestsQuery } from 'api/routes/RequestsApi';
import { useResultsQuery } from 'api/routes/ResultsApi';
import Pagination from 'components/pages/records/archive/control/pagination/Pagination';
import RecordItem from 'components/views/recordsList/recordItem/RecordItem';
import { MONTHS } from 'constants/common';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import useSortParams from 'hooks/useSortParams';
import { setRecords } from 'store/reducers/archive/archiveSlice';
import { FileType } from 'types/models';

import './RecordsList.scss';

const RecordsList: React.FC = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const { records, recordsCount, requests, isFiltered, isLoading, filteredDate } = useAppSelector(
        (state) => state.archive,
    );
    const pagination = useAppSelector((state) => state.pagination);

    const [files, setFiles] = useState<FileType[]>([]);
    const [completedResults, setCompletedResults] = useState<CompletedTranscriptPayload[]>([]);
    const [completedSearchResults, setCompletedSearchResults] = useState<CompletedSearchTranscriptPayload[]>([]);

    const sortParams = useSortParams();

    const [getFilesById] = useGetFilesByIdsMutation();
    const {
        data: resultsData,
        isSuccess: isArchiveSuccess,
        isError: isArchiveError,
    } = useResultsQuery(
        { ...filteredDate, ...pagination, ...sortParams },
        {
            pollingInterval: 60000,
            skip: isFiltered,
        },
    );
    const { isSuccess: isRequestsSuccess, isError: isRequestsError } = useRequestsQuery(null, {
        pollingInterval: isFiltered ? undefined : 60000,
    });

    // TODO: TAS-1007
    // useGetUserBalanceInfoQuery(null, {
    //     pollingInterval: isFiltered ? undefined : 60000,
    // });

    // const { folders } = useAppSelector((state) => state.folders);

    const getFiles = useCallback(
        async (ids: string[]) => {
            const reqIds = ids.filter((id) => !files.find((file) => file.file_id === id));

            await getFilesById(reqIds)
                .unwrap()
                .then(({ results: newFiles }) => {
                    // TODO: добавить аналогичную фильтрацию
                    setFiles((prev) => [...prev, ...newFiles]);
                });
        },
        [files, getFilesById],
    );

    useEffect(() => {
        getFiles(records.map((rec) => rec.file_id));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [records]);

    useEffect(() => {
        getFiles(requests.map((rec) => rec.file_id));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requests]);

    useEffect(() => {
        if (resultsData && !isFiltered) {
            dispatch(setRecords({ records: resultsData.results, count: resultsData.count }));
        }
    }, [dispatch, isFiltered, resultsData]);

    useEffect(() => {
        // закомментровано до появления папок на бэке
        // const folderProperty = searchParams.get('folder');
        // const isFolderOpen: boolean = folderProperty !== null && !isNaN(Number(folderProperty));

        if (isFiltered) {
            const completedTranscriptInfo = records.map((el) => {
                const file = files?.find((file) => file.file_id === el.file_id);
                const name = el.user_path || '';
                const stream_url = file ? file.stream_url : '';
                return { ...el, name, stream_url } as CompletedSearchTranscriptPayload;
            });

            // allRecords = allRecords.concat(sortedRecords);
            setCompletedSearchResults(completedTranscriptInfo);
        } else {
            const completedTranscriptInfo = records.map((el) => {
                const file = files?.find((file) => file.file_id === el.file_id);
                const name = el.user_path || '';
                const stream_url = file ? file.stream_url : '';
                return { ...el, name, stream_url } as CompletedTranscriptPayload;
            });

            // allRecords = allRecords.concat(sortedRecords);
            setCompletedResults(completedTranscriptInfo);
        }

        // закомментровано до появления папок на бэке
        // if (isFolderOpen) {
        //     const folderPropertyNumber = Number(folderProperty);
        //     sortedRecords = sortedRecords.filter((el) => el.folder_id === folderPropertyNumber);
        // }
    }, [files, isFiltered, records]);

    useEffect(() => {
        if (isArchiveError || isRequestsError) {
            toast.error('Ошибка обновления архива', { toastId: 'archive-error-id', position: 'bottom-right' });
        }
    }, [isArchiveError, isRequestsError]);

    let displayedMonth = 0;
    let displayedYear = 0;

    return (
        <div className='records_list'>
            <RotatingLines
                strokeColor='#814feb'
                strokeWidth='5'
                animationDuration='0.75'
                width='40'
                visible={isLoading && !(isArchiveError || isRequestsError)}
            />
            {(isArchiveError || isRequestsError) && !(isArchiveSuccess && isRequestsSuccess) && (
                <p>Не удалось загрузить архив</p>
            )}
            {!isArchiveError && isRequestsSuccess && (
                <>
                    {isFiltered && !isLoading && (
                        <>
                            <div className='records_count'>Найдено {recordsCount} записей</div>
                            {completedSearchResults.map((obj) => {
                                return (
                                    <RecordItem
                                        key={`item_${obj.id}`}
                                        id={obj.session_id}
                                        fileId={obj.file_id}
                                        timestamp={obj.timestamp}
                                        name={obj.name}
                                        streamUrl={obj.stream_url}
                                        duration={obj.duration}
                                        searchedText={obj.highlight}
                                    />
                                );
                            })}
                            {recordsCount > 0 && <Pagination count={recordsCount} {...pagination} />}
                        </>
                    )}

                    {!isFiltered &&
                        requests.map((obj) => {
                            const file = files.find((file) => file.file_id === obj.file_id);
                            return (
                                <RequestItem
                                    key={`request_${obj.id}`}
                                    id={obj.session_id}
                                    taskId={obj.task_id}
                                    name={file?.path || ''}
                                    timestamp={obj.timestamp}
                                    duration={file?.duration || 0}
                                    isFailed={obj.task_status === 'failed' || obj.task_status === 'timeout'}
                                />
                            );
                        })}
                    {!isFiltered && (
                        <>
                            {completedResults.map((obj) => {
                                let currentMonth = new Date(obj.timestamp).getMonth();
                                let currentYear = new Date(obj.timestamp).getFullYear();
                                let checkNewDate = currentMonth !== displayedMonth || currentYear !== displayedYear;

                                if (checkNewDate) {
                                    displayedMonth = currentMonth;
                                    displayedYear = currentYear;
                                }
                                return checkNewDate ? (
                                    <div key={`wrapper_${obj.id}`} style={{ width: '100%' }}>
                                        <p className='limiter_line' key={`line_${obj.id}`}>
                                            {MONTHS[displayedMonth]} {displayedYear}
                                        </p>
                                        <RecordItem
                                            key={`item_${obj.id}`}
                                            id={obj.session_id}
                                            fileId={obj.file_id}
                                            timestamp={obj.timestamp}
                                            name={obj.name}
                                            streamUrl={obj.stream_url}
                                            duration={obj.duration}
                                        />
                                    </div>
                                ) : (
                                    <RecordItem
                                        key={`item_${obj.id}`}
                                        id={obj.session_id}
                                        fileId={obj.file_id}
                                        timestamp={obj.timestamp}
                                        name={obj.name}
                                        streamUrl={obj.stream_url}
                                        duration={obj.duration}
                                    />
                                );
                            })}
                            {completedResults.length > 0 && <Pagination count={recordsCount} {...pagination} />}
                        </>
                    )}
                </>
            )}
        </div>
    );
};

export default RecordsList;
