/* eslint-disable no-unused-vars */
export const TRANSCRIPT_NAME_LENGTH_MAX_LIMIT = 50;

export const CONTENT_PER_PAGE = [1, 2, 3, 5, 10, 20, 50];

export const MILLISECONDS_PER_SECOND = 1000;

export const MONTHS = [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
];

export const MONTHS_ABBREVIATIONS = [
    'янв.',
    'фев.',
    'мар.',
    'апр.',
    'май',
    'июн.',
    'июл.',
    'авг.',
    'сен.',
    'окт.',
    'ноя.',
    'дек.',
];

export const WEEKDAY = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'];

export const SORT_PROPERTY = {
    date: 'date',
    name: 'name',
};

export const SORT_ORDER = {
    asc: 'asc',
    desc: 'desc',
};

export const SORTING_LIST = [
    { label: 'Дате создания', sortProperty: SORT_PROPERTY.date, id: 0 },
    { label: 'Названию', sortProperty: SORT_PROPERTY.name, id: 1 },
];

export const FOLDER_ACTION_TYPE = { edit: 'edit', delete: 'delete', create: 'create' };

export const FOLDER_ACTION_TYPE_LABEL = {
    [FOLDER_ACTION_TYPE.edit]: 'Редактирование',
    [FOLDER_ACTION_TYPE.delete]: 'Удаление',
    [FOLDER_ACTION_TYPE.create]: 'Создание',
};

export const CALENDAR_MODES = {
    date: 'date',
    period: 'period',
};

export const ALLOWED_TYPES = ['audio', 'video'];

export const ALLOWED_EXTENSIONS = [
    'aac',
    'ac3',
    'avi',
    'bin',
    'codec2',
    'flv',
    'm4v',
    'webm',
    'mlv',
    'mp4',
    'mp3',
    'ogg',
    'oss',
    'vmd',
    'wav',
    'xmv',
    'mkv',
];

export enum SHARE_LAVEL {
    Private = 1,
    Group = 2,
    Anonymous = 3,
}

export enum SHARE_GRANT {
    Read = 1,
    Comment = 2,
    Edit = 3,
}

export const SHARE_GRANT_LABEL = {
    [SHARE_GRANT.Read]: 'Чтение',
    [SHARE_GRANT.Comment]: 'Комментирование',
    [SHARE_GRANT.Edit]: 'Редактирование',
};

export enum USER_ROLES {
    Reader = 'reader',
    Editor = 'editor',
}

export const ROLES_LABEL = {
    [USER_ROLES.Reader]: 'Читатель',
    [USER_ROLES.Editor]: 'Редактор',
};

export const FILE_ERROR = {
    TYPE: 'type',
    UPLOADING: 'uploading',
    SIZE: 'size',
    LENGTH: 'length',
};

export const UPLOADING_ERROR_DATA_CODES = {
    FILE_SIZE_EXCEEDED: 'file_size_exceeded',
    FILE_LENGTH_EXCEEDED: 'file_length_exceeded',
};

export const UPLOADING_ERROR_MAP = {
    [UPLOADING_ERROR_DATA_CODES.FILE_SIZE_EXCEEDED]: FILE_ERROR.SIZE,
    [UPLOADING_ERROR_DATA_CODES.FILE_LENGTH_EXCEEDED]: FILE_ERROR.LENGTH,
};

export enum TRANSCRIPT_WORD_STATUS {
    Default = 0,
    Removed = -1,
    Added = 1,
}

export const TRANSCRIPT_WORD_DECORATION_MAP = {
    [TRANSCRIPT_WORD_STATUS.Default]: '',
    [TRANSCRIPT_WORD_STATUS.Removed]: 'crossed-out',
    [TRANSCRIPT_WORD_STATUS.Added]: 'underline',
};
