import { Middleware } from '@reduxjs/toolkit';

import { appApi } from 'api/routes/AppApi';
import { commentsApi } from 'api/routes/CommentsApi';
import { demoApi } from 'api/routes/DemoApi';
import { dictionaryApi } from 'api/routes/DictionaryApi';
import { recordsApi } from 'api/routes/RecordsApi';
import { requestsApi } from 'api/routes/RequestsApi';
import { resultsApi } from 'api/routes/ResultsApi';
import { speakersApi } from 'api/routes/SpeakersApi';
import { summaryApi } from 'api/routes/SummaryApi';
import { setAuthorized } from 'store/reducers/user/userSlice';

// Middleware для сброса состояния всех API, когда происходит logout
export const resetMiddleware: Middleware = (store) => (next) => (action) => {
    if (setAuthorized.match(action) && action.payload === false) {
        store.dispatch(appApi.util.resetApiState());
        store.dispatch(commentsApi.util.resetApiState());
        store.dispatch(demoApi.util.resetApiState());
        store.dispatch(dictionaryApi.util.resetApiState());
        store.dispatch(recordsApi.util.resetApiState());
        store.dispatch(requestsApi.util.resetApiState());
        store.dispatch(resultsApi.util.resetApiState());
        store.dispatch(speakersApi.util.resetApiState());
        store.dispatch(summaryApi.util.resetApiState());
    }

    return next(action);
};
