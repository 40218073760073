import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FolderModel, testFolders } from 'helpers/TestData';

interface FoldersState {
    folders: FolderModel[];
}

interface NewFolderPayload {
    name: string;
    parentFolderId: number | null;
}

const slice = createSlice({
    name: 'archive',
    initialState: { folders: testFolders } as FoldersState,
    reducers: {
        removeFolder: (state, action: PayloadAction<number>) => {
            state.folders = state.folders.filter((el) => el.id !== action.payload);
        },
        createFolder: (state, action: PayloadAction<NewFolderPayload>) => {
            const newFolderEntity = {
                id: Math.ceil(Math.random() * 1000),
                name: action.payload.name,
                root: action.payload.parentFolderId ? false : true,
                parent: action.payload.parentFolderId,
            } as FolderModel;
            state.folders.push(newFolderEntity);
        },
        editFolder: (state, action: PayloadAction<FolderModel>) => {
            state.folders = state.folders.map((el) => (el.id === action.payload.id ? action.payload : el));
        },
    },
    extraReducers: (builder) => {},
});

export const { removeFolder, createFolder, editFolder } = slice.actions;

export default slice.reducer;
