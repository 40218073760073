import { createApi } from '@reduxjs/toolkit/query/react';

import { customBaseQuery } from 'api/query';
import { SpeakersResponse } from 'api/types/controllers';
import { Speaker } from 'types/models';

const API_API_HOST = process.env.REACT_APP_API_API_HOST;

export const speakersApi = createApi({
    reducerPath: 'speakersApi',
    tagTypes: ['Speakers'],
    baseQuery: customBaseQuery({ baseUrl: `${API_API_HOST}/api/` }),
    endpoints: (build) => {
        return {
            addSpeaker: build.mutation<Speaker, string>({
                query: (name) => ({
                    url: `speakers/`,
                    method: 'POST',
                    body: { name },
                }),
                invalidatesTags: [{ type: 'Speakers' }],
            }),
            editSpeaker: build.mutation<Speaker, { id: number; name: string }>({
                query: ({ id, name }) => ({
                    url: `speakers/${id}/`,
                    method: 'PATCH',
                    body: { name },
                }),
                invalidatesTags: [{ type: 'Speakers' }],
            }),
            deleteSpeaker: build.mutation<Speaker, number>({
                query: (id) => ({
                    url: `speakers/${id}/`,
                    method: 'DELETE',
                }),
                invalidatesTags: [{ type: 'Speakers' }],
            }),
            speakers: build.query<SpeakersResponse, null>({
                query: () => ({
                    url: `speakers/`,
                    method: 'GET',
                }),
                providesTags: [{ type: 'Speakers' }],
            }),
        };
    },
});

export const { useSpeakersQuery, useAddSpeakerMutation, useEditSpeakerMutation, useDeleteSpeakerMutation } =
    speakersApi;
