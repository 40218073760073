import { configureStore } from '@reduxjs/toolkit';

import archive from './reducers/archive/archiveSlice';
import comments from './reducers/comments/commentsSlice';
import file from './reducers/file/fileSlice';
import folders from './reducers/folders/foldersSlice';
import pagination from './reducers/pagination/paginationSlice';
import audio from './reducers/transcriptions/audioSlice';
import control from './reducers/transcriptions/controlSlice';
import transcriptAccess from './reducers/transcriptions/transcriptAccess';
import transcriptAppearance from './reducers/transcriptions/transcriptAppearanceSlice';
import transcript from './reducers/transcriptions/transcriptSlice';
import user from './reducers/user/userSlice';

import { appApi } from 'api/routes/AppApi';
import { commentsApi } from 'api/routes/CommentsApi';
import { demoApi } from 'api/routes/DemoApi';
import { dictionaryApi } from 'api/routes/DictionaryApi';
import { recordsApi } from 'api/routes/RecordsApi';
import { requestsApi } from 'api/routes/RequestsApi';
import { resultsApi } from 'api/routes/ResultsApi';
import { speakersApi } from 'api/routes/SpeakersApi';
import { summaryApi } from 'api/routes/SummaryApi';
import { resetMiddleware } from 'helpers/ResetMiddleware';

const store = configureStore({
    reducer: {
        pagination,
        transcript,
        transcriptAccess,
        transcriptAppearance,
        audio,
        control,
        comments,
        archive,
        user,
        folders,
        file,
        [appApi.reducerPath]: appApi.reducer,
        [recordsApi.reducerPath]: recordsApi.reducer,
        [demoApi.reducerPath]: demoApi.reducer,
        [commentsApi.reducerPath]: commentsApi.reducer,
        [dictionaryApi.reducerPath]: dictionaryApi.reducer,
        [requestsApi.reducerPath]: requestsApi.reducer,
        [resultsApi.reducerPath]: resultsApi.reducer,
        [speakersApi.reducerPath]: speakersApi.reducer,
        [summaryApi.reducerPath]: summaryApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            immutableCheck: { warnAfter: 128 },
            serializableCheck: false,
        })
            .concat(appApi.middleware)
            .concat(recordsApi.middleware)
            .concat(demoApi.middleware)
            .concat(commentsApi.middleware)
            .concat(dictionaryApi.middleware)
            .concat(requestsApi.middleware)
            .concat(resultsApi.middleware)
            .concat(speakersApi.middleware)
            .concat(summaryApi.middleware)
            .concat(resetMiddleware),
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
