import { createSlice } from '@reduxjs/toolkit';
import {
    MutationDefinition,
    BaseQueryFn,
    FetchArgs,
    FetchBaseQueryError,
    FetchBaseQueryMeta,
} from '@reduxjs/toolkit/dist/query';
import { MutationActionCreatorResult } from '@reduxjs/toolkit/dist/query/core/buildInitiate';

import { recordsApi } from 'api/routes/RecordsApi';
import { FileUploadResultResponse } from 'api/types/controllers';
import { FileInfoPayload } from 'components/views/recordUploader/UploadingPage.interface';

interface ActiveUpload {
    fileInfo: FileInfoPayload;
    loading: boolean;
    fulfilled: boolean;
    rejected: boolean;
    canceled: boolean;
    request: MutationActionCreatorResult<
        MutationDefinition<
            FormData,
            BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, FetchBaseQueryMeta>,
            never,
            FileUploadResultResponse,
            'recordsApi'
        >
    > | null;
}

interface FileState {
    activeUpload: ActiveUpload;
}

const initialState = {
    activeUpload: {
        loading: false,
        rejected: false,
        fulfilled: false,
        canceled: false,
        fileInfo: {
            name: '',
            id: '',
            is_already_transcribed: false,
            is_transcribing: false,
        },
        request: null,
    },
} as FileState;

const slice = createSlice({
    name: 'files',
    initialState,
    reducers: {
        resetState: () => initialState,
        setActiveUploadName: (state, { payload }) => {
            state.activeUpload = {
                ...initialState.activeUpload,
                fileInfo: { ...state.activeUpload.fileInfo, name: payload },
                loading: true,
            };
        },
        setActiveUploadRequest: (state, { payload }) => {
            state.activeUpload.request = payload;
        },
        cancelActiveUpload: (state) => {
            state.activeUpload = { ...initialState.activeUpload, canceled: true };
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(recordsApi.endpoints.uploadFile.matchFulfilled, (state, { payload }) => {
            state.activeUpload.fileInfo = {
                ...state.activeUpload.fileInfo,
                id: payload.file_id,
                is_already_transcribed: payload.is_already_transcribed,
                is_transcribing: payload.is_transcribing,
            };
            state.activeUpload.loading = false;
            state.activeUpload.fulfilled = true;
        });
        builder.addMatcher(recordsApi.endpoints.uploadFile.matchRejected, (state) => {
            state.activeUpload.loading = false;
            state.activeUpload.rejected = true;
        });
    },
});

export const { setActiveUploadName, resetState, setActiveUploadRequest, cancelActiveUpload } = slice.actions;

export default slice.reducer;
