import { useState } from 'react';

interface UsePaginationProps {
    limit: number;
    offset: number;
    count: number;
}
interface UsePaginationReturn {
    page: number;
    totalPages: number;
    nextPage: () => void;
    prevPage: () => void;
    setPage: (page: number) => void;
}
type UsePagination = (UsePaginationProps: UsePaginationProps) => UsePaginationReturn;

const usePagination: UsePagination = ({ limit, offset, count }) => {
    const [page, setPage] = useState(offset / limit + 1);

    // number of pages in total (total items / content on each page)
    const pageCount = Math.ceil(count / limit);
    // index of last item of current page

    // change page based on direction either front or back
    const changePage = (direction: boolean) => {
        setPage((state) => {
            // move forward
            if (direction) {
                // if page is the last page, do nothing
                if (state === pageCount) {
                    return state;
                }
                return state + 1;
                // go back
            } else {
                // if page is the first page, do nothing
                if (state === 1) {
                    return state;
                }
                return state - 1;
            }
        });
    };
    const setPageSAFE = (num: number) => {
        // if number is greater than number of pages, set to last page
        if (num > pageCount) {
            setPage(pageCount);
            // if number is less than 1, set page to first page
        } else if (num < 1) {
            setPage(1);
        } else {
            setPage(num);
        }
    };
    return {
        totalPages: pageCount,
        nextPage: () => changePage(true),
        prevPage: () => changePage(false),
        setPage: setPageSAFE,
        page,
    };
};
export default usePagination;
