import { useNavigate } from 'react-router-dom';

import { UploadingProgressProps } from '../UploadingPage.interface';

import './UploadingProgress.scss';

const UploadingProgress: React.FC<UploadingProgressProps> = ({ navigateToFirstStep }) => {
    const navigate = useNavigate();
    return (
        <div className='decryption-info_container'>
            {/* Закомментировано до появления отображения активных расшифровок на бэке */}
            {/* <div className='progress_wrapper'>
                <p className='progress_percent'>{progress}%</p>
                <ProgressBar progress={progress} animated />
            </div> */}
            <div className='tip'>
                Результат работы вы найдете в разделе{' '}
                <span
                    className='link'
                    onClick={() => {
                        navigate('/archive');
                    }}
                >
                    Мои записи
                </span>
            </div>
            <div className='btn btn_secondary' onClick={navigateToFirstStep}>
                Вернуться в начало
            </div>
        </div>
    );
};

export default UploadingProgress;
