import ReactQuill from 'react-quill';

import 'react-quill/dist/quill.snow.css';
import { TextEditorProps } from './TextEditor.interface';
import './TextEditor.scss';

export const TextEditor = ({ text, handleChange, readOnly = false }: TextEditorProps) => {
    return (
        <ReactQuill
            theme='snow'
            value={text}
            onChange={handleChange}
            className={`quill_text_editor ${readOnly ? 'disabled' : ''}`}
            readOnly={readOnly}
        />
    );
};
