import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ControlState {
    selectedReplicaId: string | null;
    createCommentFlag: boolean;
    selectedWordsText: string | null;
}

const initialState: ControlState = {
    selectedReplicaId: null,
    createCommentFlag: false,
    selectedWordsText: null,
};

const slice = createSlice({
    name: 'control',
    initialState: initialState,
    reducers: {
        setSelectedReplica(state, { payload: replicaId }: PayloadAction<string | null>) {
            state.selectedReplicaId = replicaId;
        },
        setCreateCommentFlag(state, { payload: flag }: PayloadAction<boolean>) {
            state.createCommentFlag = flag;
        },
        setSelectedWordsText(state, { payload: text }: PayloadAction<string | null>) {
            state.selectedWordsText = text;
        },
    },
    extraReducers: (builder) => {},
});

export const { setSelectedReplica, setCreateCommentFlag, setSelectedWordsText } = slice.actions;
export default slice.reducer;
