import { createApi } from '@reduxjs/toolkit/dist/query/react';

import { customBaseQuery } from 'api/query';
import {
    SummaryResponse,
    GetSummaryPayload,
    UpdateSummaryPayload,
    GenerateSummaryPayload,
    TablesResponse,
} from 'api/types/controllers';

const RECORDS_API_HOST = process.env.REACT_APP_RECORDS_API_HOST;

export const summaryApi = createApi({
    reducerPath: 'summaryApi',
    baseQuery: customBaseQuery({
        baseUrl: `${RECORDS_API_HOST}/api/file/summary/`,
    }),
    tagTypes: ['Summary'],
    endpoints: (build) => ({
        getBrief: build.query<SummaryResponse, GetSummaryPayload>({
            query: ({ fileId }) => ({
                url: `brief/${fileId}/`,
                method: 'GET',
            }),
            providesTags: [{ type: 'Summary', id: 'brief' }],
        }),
        getTopics: build.query<SummaryResponse, GetSummaryPayload>({
            query: ({ fileId }) => ({
                url: `topics/${fileId}/`,
                method: 'GET',
            }),
            providesTags: [{ type: 'Summary', id: 'topics' }],
        }),
        getTasks: build.query<SummaryResponse, GetSummaryPayload>({
            query: ({ fileId }) => ({
                url: `tasks/${fileId}/`,
                method: 'GET',
            }),
            providesTags: [{ type: 'Summary', id: 'tasks' }],
        }),
        getTables: build.query<TablesResponse, GetSummaryPayload>({
            query: ({ fileId }) => ({
                url: `tables/${fileId}/`,
                method: 'GET',
            }),
            providesTags: [{ type: 'Summary', id: 'tables' }],
        }),
        getProtocol: build.query<TablesResponse, GetSummaryPayload>({
            query: ({ fileId }) => ({
                url: `protocol/${fileId}/`,
                method: 'GET',
            }),
            providesTags: [{ type: 'Summary', id: 'protocol' }],
        }),

        generateSummary: build.mutation<SummaryResponse, GenerateSummaryPayload>({
            query: ({ fileId, view }) => ({
                url: `${view}/${fileId}/`,
                method: 'POST',
            }),
            invalidatesTags: (_, __, arg) => [{ type: 'Summary', id: arg.view }],
        }),
        updateSummary: build.mutation<SummaryResponse, UpdateSummaryPayload>({
            query: ({ fileId, view, text }) => ({
                url: `${view}/${fileId}/`,
                method: 'PUT',
                body: { text },
            }),
            invalidatesTags: (_, __, arg) => [{ type: 'Summary', id: arg.view }],
        }),
    }),
});

export const {
    useGetBriefQuery,
    useGetTasksQuery,
    useGetTablesQuery,
    useGetTopicsQuery,
    useGenerateSummaryMutation,
    useUpdateSummaryMutation,
    useGetProtocolQuery,
} = summaryApi;
