import React from 'react';

const NotCreatedPage: React.FC = (): JSX.Element => {
    return (
        <div
            style={{
                width: '100vw',
                height: '60vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '1.5rem',
                color: '#544081',
            }}
        >
            Раздел в стадии разработки
        </div>
    );
};

export default NotCreatedPage;
