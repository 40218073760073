import { DemoTablesViewerProps } from './DemoTablesViewer.interface';
import './DemoTables.scss';

const DemoTablesViewer: React.FC<DemoTablesViewerProps> = ({ tables }) => {
    return (
        <div className='tables-viewer'>
            <div className='tables-viewer__list'>
                {tables?.map((table, index) => (
                    <div key={`${index}-${table.name}`}>
                        <div className='tables-viewer__name'>{table.name}</div>
                        <table className={`tables-viewer__table ${table.data.columns.length === 1 && 'only-column'}`}>
                            <thead>
                                <tr>
                                    {table.data.columns.map((column, columnIndex) => (
                                        <th key={`${index}-${columnIndex}`} title={column}>
                                            {column}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {table.data.rows.map((row, rowIndex) => (
                                    <tr key={`${index}-${rowIndex}`}>
                                        {row.map((v, index) => (
                                            <td key={`${v}-${index}`} title={v}>
                                                {v}
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default DemoTablesViewer;
