import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { useResetPasswordMutation } from 'api/routes/AppApi';
import { ErrorMessage } from 'components/generic/errorMessage/ErrorMessage';
import { ValidatedInput } from 'components/generic/inputFields/InputFields';
import { inputTypes } from 'components/generic/inputFields/InputFields.interfaces';
import { emailPattern } from 'helpers/ValidationPatterns';

const ResetPasswordForm: React.FC = () => {
    const navigate = useNavigate();
    const [resetPassword, { isError }] = useResetPasswordMutation();
    const [errorMessage, setErrorMessage] = useState('');
    const methods = useForm({
        mode: 'onChange',
        defaultValues: {
            email: '',
        },
    });

    return (
        <div className='reset_password_container'>
            <FormProvider {...methods}>
                <form
                    className='section_form'
                    onSubmit={methods.handleSubmit((data) => {
                        resetPassword(data)
                            .unwrap()
                            .then(() => navigate('confirm'))
                            .catch(() => {
                                setErrorMessage('Пользователь с данным email не зарегистрирован ');
                            });
                    })}
                >
                    <p className='title'>Восстановление пароля</p>
                    <div className='error_loader_container'>{isError && <ErrorMessage message={errorMessage} />}</div>
                    <div className='input_wrapper'>
                        <ValidatedInput
                            required
                            field='email'
                            pattern={emailPattern}
                            placeholder='Email'
                            type={inputTypes.email}
                        />
                        {methods.formState.errors.email && (
                            <ErrorMessage message={methods.formState.errors.email.message} />
                        )}
                    </div>
                    <button type='submit' className='btn btn_primary'>
                        Отправить ссылку
                    </button>
                </form>
            </FormProvider>
        </div>
    );
};
export default ResetPasswordForm;
