import { USER_ROLES, SHARE_GRANT, FILE_ERROR } from './common';

export const AUTH_ERROR_MESSAGES: { [status: string]: string } = {
    400: 'Почта уже используется',
    401: 'Неверный адрес электронной почты или пароль',
    500: 'Ошибка сервера. Попробуйте позже',
    default: 'Что-то пошло не так',
};

export const ROLES_DESCRIPTION = {
    [USER_ROLES.Reader]:
        'Все пользователи, которым открыт доступ к расшифровке, могут просматривать и скачивать текст стенограммы',
    [USER_ROLES.Editor]:
        'Все пользователи, которым открыт доступ к расшифровке, могут просматривать, редактировать и скачивать текст стенограммы, а также скачивать аудио стенограммы ',
};

export const SHARE_GRANT_DESCRIPTION = {
    [SHARE_GRANT.Read]: 'Все пользователи, у которых есть ссылка, могут просматривать и скачивать текст стенограммы',
    [SHARE_GRANT.Comment]:
        'Все пользователи, у которых есть ссылка, могут просматривать, скачивать и комментировать текст стенограммы',
    [SHARE_GRANT.Edit]:
        'Все пользователи, у которых есть ссылка, могут просматривать, скачивать, комментировать и редактировать текст стенограммы',
};

export const UPLOADING_FILE_ERROR_MESSAGES = {
    [FILE_ERROR.TYPE]: 'не соответствует аудио или видео формату.',
    [FILE_ERROR.UPLOADING]: 'произошла ошибка при отправке на сервер. Попробуйте позже или выберите другой файл.',
    [FILE_ERROR.SIZE]: 'размер превышает 200 МБ.',
    [FILE_ERROR.LENGTH]: 'длина превышает 60 минут.',
};
