import { RotatingLines } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';
// import { Controller } from 'react-hook-form';

import { UploadedFileProps } from './UploadedFile.interface';

import { useGetSessionByFileIdQuery } from 'api/routes/RequestsApi';
import { ReactComponent as ClearIcon } from 'assets/cross-in-circle.svg';
// import { ReactComponent as QuestionIcon } from 'assets/question-circle.svg';
import { ErrorMessage } from 'components/generic/errorMessage/ErrorMessage';
// import Toggle from 'components/generic/toggle/Toggle';
import { TRANSCRIPT_NAME_LENGTH_MAX_LIMIT } from 'constants/common';

import './UploadedFile.scss';

export const UploadedFile = ({ file, formMethods }: UploadedFileProps) => {
    const { data: sessionData, isLoading } = useGetSessionByFileIdQuery(file.id, {
        skip: file.is_already_transcribed === false,
    });
    const navigate = useNavigate();

    const {
        register,
        watch,
        formState: { errors },
        // control,
        setValue,
    } = formMethods;

    return (
        <div key={file.id} className='item'>
            <RotatingLines
                strokeColor='#814feb'
                strokeWidth='5'
                animationDuration='0.75'
                width='40'
                visible={isLoading}
            />
            {!isLoading && (
                <>
                    {file.is_transcribing ? (
                        <div className='uploaded-file_wrapper'>
                            <span>
                                Расшифровка файла <span className='uploaded-file_name'>{file.name}</span> в процессе.
                                Результат работы вы найдете в разделе{' '}
                                <span
                                    className='link'
                                    onClick={() => {
                                        navigate('/archive');
                                    }}
                                >
                                    Мои записи
                                </span>
                            </span>
                        </div>
                    ) : file.is_already_transcribed ? (
                        <div className='uploaded-file_wrapper'>
                            <span>
                                Стенограмма для файла <span className='uploaded-file_name'>{file.name}</span> уже
                                доступна.{' '}
                                <span
                                    className='link'
                                    onClick={() => {
                                        navigate(`/archive/transcript/${sessionData?.session_id}`);
                                    }}
                                >
                                    Открыть расшифровку
                                </span>
                            </span>
                        </div>
                    ) : (
                        <>
                            <div className='input_wrapper'>
                                <label className='label'>Название стенограммы</label>
                                <input
                                    {...register(`${file.id}.user_path`)}
                                    className='input'
                                    autoComplete='off'
                                    maxLength={TRANSCRIPT_NAME_LENGTH_MAX_LIMIT}
                                    required
                                    autoFocus
                                    style={{ padding: '6px 37px 6px 12px' }}
                                />
                                {watch(`${file.id}.user_path`) && (
                                    <ClearIcon
                                        className='clear_icon'
                                        onClick={() => setValue(`${file.id}.user_path`, '')}
                                    />
                                )}
                                {errors[`${file.id}.user_path`] && (
                                    <ErrorMessage message={errors[`${file.id}.user_path`]?.message?.toString()} />
                                )}
                            </div>
                            <span>Максимальная длина: 50 символов</span>
                        </>
                    )}
                </>
            )}
            {/* число спикеров временно не учитывается */}
            {/* <div className='input_wrapper'>
        <label className='label'>Число участников</label>
        <div className='speakers_count_wrapper'>
            <Controller
                control={control}
                name={`${file.id}.unknownSpeakersNumber`}
                render={({ field: { value, onChange } }) => (
                    <>
                        <input
                            {...register(`${file.id}.num_speakers`)}
                            className='input'
                            min={1}
                            type='number'
                            required={!value}
                            disabled={value}
                        />
                        <Toggle
                            id='unknown-speakers-count'
                            selected={value}
                            setSelected={onChange}
                            text='Неизвестно'
                        />
                    </>
                )}
            />
        </div>
    </div> */}
            {/* Скрыто на время показа */}
            {/* <div className='diarization_wrapper'>
        <Controller
            control={control}
            name={`${file.id}.enable_annotation`}
            render={({ field: { value, onChange } }) => (
                <Toggle
                    selected={value}
                    id='diarization'
                    setSelected={onChange}
                    text='Использовать диаризацию'
                />
            )}
        />
        <div className='tooltip'>
            <QuestionIcon className='icon' />
            <span className='tooltiptext'>
                Диаризация - разбиение текста на реплики на основе соответствия аудиопотока тому или
                иному говорящему
            </span>
        </div>
    </div> */}
        </div>
    );
};
