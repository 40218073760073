import { useCallback, useState } from 'react';

import { RotatingLines } from 'react-loader-spinner';

import { ChooseSpeakerModalProps } from '../../Transcript.interface';

import { useSpeakersQuery, useAddSpeakerMutation } from 'api/routes/SpeakersApi';
import { ErrorMessage } from 'components/generic/errorMessage/ErrorMessage';
import { ModalWithDimming } from 'components/generic/modal/Modal';

const ChooseSpeakerModal: React.FC<ChooseSpeakerModalProps> = ({ setModalIsOpen, handleSpeakerChange }) => {
    const {
        data: speakersData,
        isLoading: isSpeakersLoading,
        isError: isSpeakersError,
        isSuccess: isSpeakersSuccess,
    } = useSpeakersQuery(null);
    const [createNewSpeaker] = useAddSpeakerMutation();

    const [name, setName] = useState<string>('');
    const [error, setError] = useState<boolean>(false);

    const handleAddSpeaker = () => {
        if (name.length === 0) {
            setError(true);
            return;
        }
        createNewSpeaker(name);
        handleSpeakerChange(name);
        setModalIsOpen(false);
    };

    const handleChooseSpeaker = useCallback(
        (speaker: string) => {
            handleSpeakerChange(speaker);
            setModalIsOpen(false);
        },
        [handleSpeakerChange, setModalIsOpen],
    );

    const handleSpeakerInput = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setError(false);
        setName(e.target.value);
    }, []);

    return (
        <ModalWithDimming
            hideModal={() => setModalIsOpen(false)}
            modalTitle='Выбрать спикера'
            modalBody={
                <div className='choose_speaker_modal'>
                    <ul className='speakers_list'>
                        {isSpeakersError && <ErrorMessage message='Не удалось получить список спикеров' />}
                        {isSpeakersLoading && (
                            <RotatingLines strokeColor='#814feb' strokeWidth='5' animationDuration='0.75' width='30' />
                        )}
                        {isSpeakersSuccess &&
                            [...speakersData.results]
                                ?.sort((a, b) => (a.name > b.name ? 1 : -1))
                                .map((speaker) => (
                                    <li
                                        className='speakers_list__item'
                                        key={speaker.id}
                                        onClick={() => handleChooseSpeaker(speaker.name)}
                                    >
                                        {speaker.name}
                                    </li>
                                ))}
                    </ul>
                    <div>
                        <form
                            className='add_speaker_wrapper'
                            onSubmit={(e) => {
                                e.preventDefault();
                                handleAddSpeaker();
                            }}
                        >
                            <div className='input_wrapper'>
                                <input
                                    placeholder='Введите спикера'
                                    className={`input  ${error && 'is_invalid'}`}
                                    id='speaker_name'
                                    onChange={handleSpeakerInput}
                                ></input>
                                {error && <ErrorMessage message='Имя не может быть пустым' />}
                            </div>
                            <button className='btn btn_secondary' type='submit'>
                                Добавить спикера
                            </button>
                        </form>
                    </div>
                </div>
            }
        />
    );
};

export default ChooseSpeakerModal;
