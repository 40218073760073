import { createApi } from '@reduxjs/toolkit/query/react';

import { customBaseQuery } from 'api/query';
import { DictionariesResponse, NewDictionaryPayload, NewDictionaryWordPayload } from 'api/types/controllers';
import { Dictionary, DictionaryWord } from 'types/models';

const API_API_HOST = process.env.REACT_APP_API_API_HOST;

export const dictionaryApi = createApi({
    reducerPath: 'dictionaryApi',
    tagTypes: ['DictionariesList', 'Dictionary'],
    baseQuery: customBaseQuery({ baseUrl: `${API_API_HOST}/api/` }),
    endpoints: (build) => {
        return {
            getDictionariesList: build.query<DictionariesResponse, null>({
                query: () => ({
                    url: 'dict/',
                    method: 'GET',
                }),
                providesTags: [{ type: 'DictionariesList' }],
            }),
            deleteDictionary: build.mutation<void, number>({
                query: (id) => ({
                    url: `dict/${id}/`,
                    method: 'DELETE',
                }),
                invalidatesTags: [{ type: 'DictionariesList' }],
            }),
            createDictionary: build.mutation<Dictionary, NewDictionaryPayload>({
                query: (body) => ({
                    url: `dict/`,
                    method: 'POST',
                    body: body,
                }),
                invalidatesTags: [{ type: 'DictionariesList' }],
            }),
            getDictionary: build.query<Dictionary, string>({
                query: (dict_id) => ({
                    url: `dict/${dict_id}/`,
                    method: 'GET',
                }),
                providesTags: [{ type: 'Dictionary' }],
            }),
            addDictionaryWord: build.mutation<DictionaryWord, NewDictionaryWordPayload>({
                query: (word) => ({
                    url: `dict/${word.dict}/items/`,
                    method: 'POST',
                    body: word,
                }),
                invalidatesTags: [{ type: 'Dictionary' }],
            }),
            deleteDictionaryWord: build.mutation<void, { dict: string; wordId: number }>({
                query: ({ dict, wordId }) => ({
                    url: `dict/${dict}/items/${wordId}/`,
                    method: 'DELETE',
                }),
                invalidatesTags: [{ type: 'Dictionary' }],
            }),
            editDictionaryWord: build.mutation<DictionaryWord, { wordId: number; word: NewDictionaryWordPayload }>({
                query: ({ wordId, word }) => ({
                    url: `dict/${word.dict}/items/${wordId}/`,
                    method: 'PUT',
                    body: word,
                }),
                invalidatesTags: [{ type: 'Dictionary' }],
            }),
        };
    },
});

export const {
    useGetDictionariesListQuery,
    useDeleteDictionaryMutation,
    useGetDictionaryQuery,
    useCreateDictionaryMutation,
    useAddDictionaryWordMutation,
    useDeleteDictionaryWordMutation,
    useEditDictionaryWordMutation,
} = dictionaryApi;
