import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { useChangePasswordMutation } from 'api/routes/AppApi';
import { ErrorMessage } from 'components/generic/errorMessage/ErrorMessage';
import { ValidatedInput } from 'components/generic/inputFields/InputFields';
import { inputTypes } from 'components/generic/inputFields/InputFields.interfaces';

const ChangePasswordForm: React.FC = () => {
    const navigate = useNavigate();
    const [changePassword, { isError }] = useChangePasswordMutation();
    const [errorMessage, setErrorMessage] = useState('');
    const { token } = useParams();

    const methods = useForm({
        mode: 'onChange',
        defaultValues: {
            password: '',
        },
    });

    return (
        <div className='reset_password_container'>
            <FormProvider {...methods}>
                <form
                    className='section_form'
                    onSubmit={methods.handleSubmit((data) => {
                        changePassword({ token, new_password: data.password })
                            .unwrap()
                            .then(() => navigate('login'))
                            .catch(() => {
                                setErrorMessage('Не удалось изменить пароль');
                            });
                    })}
                >
                    <p className='title'>Изменение пароля</p>
                    <div className='error_loader_container'>{isError && <ErrorMessage message={errorMessage} />}</div>
                    <div className='input_wrapper'>
                        <ValidatedInput
                            required
                            abilityToHide
                            field='password'
                            placeholder='Новый пароль'
                            type={inputTypes.password}
                        />
                        {methods.formState.errors.password && (
                            <ErrorMessage message={methods.formState.errors.password.message} />
                        )}
                    </div>
                    <button type='submit' className='btn btn_primary'>
                        Сохранить
                    </button>
                </form>
            </FormProvider>
        </div>
    );
};
export default ChangePasswordForm;
