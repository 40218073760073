import classNames from 'classnames';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useDeleteFileMutation, useLazyDownloadFileByIdQuery } from 'api/routes/RecordsApi';
import { useLazyDownloadTranscriptFileQuery } from 'api/routes/ResultsApi';
import { ReactComponent as MenuIcon } from 'assets/menu/menu.svg';
import { ReactComponent as DocumentIcon } from 'assets/paper.svg';
import { ReactComponent as AudioIcon } from 'assets/sound.svg';
import { ReactComponent as TrashIcon } from 'assets/trash-can.svg';
import ConfirmDeleteModal from 'components/generic/modal/confirmDeleteModal/ConfirmDeleteModal';
import { useAppSelector } from 'hooks/useAppSelector';
import useOutsideClick from 'hooks/useOutsideClick';

import './TranscriptControlMenu.scss';

const TranscriptControlMenu: React.FC = () => {
    const navigate = useNavigate();

    const [deleteFile] = useDeleteFileMutation();
    const [downloadFile] = useLazyDownloadTranscriptFileQuery();
    const [downloadRecord] = useLazyDownloadFileByIdQuery();

    const { transcript } = useAppSelector((state) => state.transcript);
    const { isEditingAllowed, shareAccessToken } = useAppSelector((state) => state.transcriptAccess);

    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    useOutsideClick([dropdownRef], () => {
        setIsDropdownOpen(false);
    });

    return (
        <>
            <div className='control-menu' ref={dropdownRef}>
                <button className='btn btn_with_icon btn_secondary' onClick={() => setIsDropdownOpen((prev) => !prev)}>
                    <MenuIcon />
                </button>
                <ul className={classNames('dropdown_menu', { 'dropdown_menu--open': isDropdownOpen })}>
                    <li
                        className='dropdown_menu__item'
                        onClick={() => {
                            downloadFile({
                                req_id: transcript.session_id,
                                doc_format: 'docx',
                                name: transcript.user_path,
                                shareAccessToken,
                            });
                            setIsDropdownOpen(false);
                        }}
                    >
                        <DocumentIcon />
                        <span>Скачать текст</span>
                    </li>
                    <li
                        className='dropdown_menu__item'
                        onClick={() => {
                            downloadRecord({ fileId: transcript.file_id, shareAccessToken });
                            setIsDropdownOpen(false);
                        }}
                    >
                        <AudioIcon />
                        <span>Скачать запись</span>
                    </li>
                    {isEditingAllowed && (
                        <li
                            className='dropdown_menu__item control-menu__delete'
                            onClick={() => {
                                setIsDropdownOpen(false);
                                setDeleteModalIsOpen(true);
                            }}
                        >
                            <TrashIcon />
                            <span>Удалить запись</span>
                        </li>
                    )}
                </ul>
            </div>
            {deleteModalIsOpen && (
                <ConfirmDeleteModal
                    onClose={() => setDeleteModalIsOpen(false)}
                    onOk={() => {
                        setDeleteModalIsOpen(false);
                        deleteFile(transcript?.file_id || '');
                        navigate('/archive');
                    }}
                />
            )}
        </>
    );
};

export default TranscriptControlMenu;
