import ask from 'assets/faq/ask.svg';

import './FAQ.scss';

const FAQ: React.FC = () => (
    <div id='faq' className='landing-section'>
        <div className='content'>
            <div className='note'>
                <div className='note__ask'>
                    <img className='note__ask-img' src={ask} alt='ask-icon' />
                    <span>Какие языки поддерживает ваша система?</span>
                </div>
                <div className='note__answer'>
                    <span>Русский и английский</span>
                </div>
            </div>
        </div>
    </div>
);

export default FAQ;
