import {
    addDays,
    endOfMonth,
    endOfWeek,
    format,
    getDay,
    getISOWeek,
    getYear,
    isSameDay,
    isSameMonth,
    startOfMonth,
    startOfWeek,
} from 'date-fns';
import { useCallback, useMemo } from 'react';

import { CellsProps } from 'components/views/searchFilter/calendar/Calendar.interfaces';
import { CALENDAR_MODES } from 'constants/common';

import './CalendarCells.scss';

const CalendarCells: React.FC<CellsProps> = (props): JSX.Element => {
    const handleCellClick = useCallback(
        (date: Date): void => {
            if (props.currentMode === CALENDAR_MODES.date) {
                props.setSearchDate(date);
            } else {
                if (props.rangeSelector.waiting === false || props.rangeSelector.startOfOPeriod > date) {
                    props.changeRangeOfDates({
                        ...props.rangeSelector,
                        startOfOPeriod: date,
                        waiting: true,
                        focus: true,
                    });
                    props.changeSelectedPeriod([date]);
                } else if (!isSameDay(date, props.rangeSelector.startOfOPeriod)) {
                    props.changeRangeOfDates({ ...props.rangeSelector, endOfOPeriod: date, waiting: false });

                    let newPeriod: Date[] = [];
                    let day: Date = props.rangeSelector.startOfOPeriod;
                    let endRange: Date = date;

                    while (day <= endRange) {
                        newPeriod.push(day);
                        day = addDays(day, 1);
                    }
                    props.changeSelectedPeriod(newPeriod);

                    props.setSelectedDateRange(newPeriod[0], newPeriod[newPeriod.length - 1]);
                }
            }
        },
        [props],
    );

    const isDateInRange = useCallback(
        (date: Date): boolean => {
            const day = props.selectedPeriod.find((d) => isSameDay(d, date));
            return day !== undefined;
        },
        [props.selectedPeriod],
    );

    const dateRangeCustom = useCallback(
        (date: Date, index: number): string => {
            if (!isDateInRange(date)) {
                return '';
            } else {
                if (isSameDay(date, props.selectedPeriod[0]) || index === 0) {
                    return 'left_side_of_range';
                } else if (isSameDay(date, props.selectedPeriod[props.selectedPeriod.length - 1]) || index === 6) {
                    return 'right_side_of_range';
                } else {
                    return 'period_cell';
                }
            }
        },
        [isDateInRange, props.selectedPeriod],
    );

    const calendarRenderer = useMemo(() => {
        const selectedDate = props.selectedDate;
        const currentDate = new Date();

        const monthStart = startOfMonth(selectedDate);
        const monthEnd = endOfMonth(monthStart);
        const startDate = addDays(startOfWeek(monthStart), 1);
        const endDate = endOfWeek(monthEnd);
        const startWeekNumber = getISOWeek(monthStart);

        const dateFormat = 'd';

        let rows = [];

        let weeks = [];
        let days = [];
        let day = startDate;
        let week = startWeekNumber;
        let formattedDate = '';

        while (day <= endDate) {
            week = getISOWeek(addDays(day, 1));
            weeks.push(
                <div
                    key={day.toDateString()}
                    className={`weeks_cell ${
                        getYear(day) > getYear(currentDate) ||
                        (week > getISOWeek(currentDate) && getYear(day) === getYear(currentDate))
                            ? 'disabled'
                            : 'previous'
                    }`}
                >
                    <span className='number'>{week}</span>
                </div>,
            );

            for (let i = 0; i < 7; i++) {
                formattedDate = format(day, dateFormat);
                const cloneDay = day;

                days.push(
                    <button
                        key={day.toDateString()}
                        className={`calendar_cell ${
                            !isSameMonth(day, monthStart)
                                ? 'disabled'
                                : getDay(day) === 0 || getDay(day) === 6
                                ? 'holiday_cell'
                                : ''
                        }
                        ${isSameDay(day, currentDate) ? 'current' : ''} ${
                            props.searchedDate && isSameDay(day, props.searchedDate) ? 'selected' : ''
                        }  ${dateRangeCustom(day, i)}`}
                        onClick={() => handleCellClick(cloneDay)}
                    >
                        <span className='number'>{formattedDate}</span>
                    </button>,
                );
                day = addDays(day, 1);
            }
            rows.push(
                <div key={getISOWeek(day) + getYear(day)} className='calendar_row'>
                    {days}
                </div>,
            );
            days = [];
        }
        return { weeks, rows };
    }, [props.selectedDate, props.searchedDate, dateRangeCustom, handleCellClick]);

    return (
        <div className='calendar_table'>
            <div className='weeks_table'>{calendarRenderer.weeks}</div>
            <div className='days_table'>{calendarRenderer.rows}</div>
        </div>
    );
};

export default CalendarCells;
