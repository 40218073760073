import { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { AddEmailFormProps } from './AddEmailForm.interface';

import { ReactComponent as PlusIcon } from 'assets/plus.svg';
import { ErrorMessage } from 'components/generic/errorMessage/ErrorMessage';
import { ValidatedInput } from 'components/generic/inputFields/InputFields';
import { inputTypes } from 'components/generic/inputFields/InputFields.interfaces';
import { emailPattern } from 'helpers/ValidationPatterns';
import '../ShareResultModal.scss';

export const AddEmailForm: FC<AddEmailFormProps> = ({ addEmail }) => {
    const form = useForm<{ email: string }>({
        mode: 'onChange',
        defaultValues: {
            email: '',
        },
    });

    const {
        reset,
        handleSubmit,
        formState: { errors, isValid },
    } = form;

    return (
        <FormProvider {...form}>
            <form
                className='share-result-modal__add-email'
                onSubmit={handleSubmit((email) => {
                    addEmail(email.email);
                    reset();
                })}
            >
                <label>
                    Email
                    <ValidatedInput
                        required
                        field='email'
                        pattern={emailPattern}
                        placeholder='example@email.com'
                        type={inputTypes.email}
                        className='input'
                    />
                </label>
                <button type='submit' className='btn btn_with_icon btn_secondary' disabled={!isValid}>
                    <PlusIcon />
                </button>
            </form>
            {errors.email && <ErrorMessage message={errors.email.message} />}
        </FormProvider>
    );
};
