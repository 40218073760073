import { createApi } from '@reduxjs/toolkit/dist/query/react';

import { customBaseQuery } from 'api/query';
import {
    DemoModel,
    DemoPayload,
    DemoProjectsInfoModel,
    DemoProjectsInfoPayload,
    DemoProjectsPayload,
    DemoTablesPayload,
} from 'api/types/controllers';

const REACT_APP_LLM_API_HOST = process.env.REACT_APP_LLM_API_HOST;

export const demoApi = createApi({
    reducerPath: 'demoApi',
    baseQuery: customBaseQuery({
        baseUrl: `${REACT_APP_LLM_API_HOST}`,
        //не надо на хф слать bearer ключ, он от этого страдает предположительно
    }),
    endpoints: (build) => ({
        getBrief: build.mutation<DemoPayload, DemoModel>({
            query: (text) => ({
                url: `getsummary`,
                method: 'POST',
                body: text,
            }),
        }),
        getTopics: build.mutation<DemoPayload, DemoModel>({
            query: (text) => ({
                url: `getfollowup`,
                method: 'POST',
                body: text,
            }),
        }),
        getTasks: build.mutation<DemoPayload, DemoModel>({
            query: (text) => ({
                url: `getagenda`,
                method: 'POST',
                body: text,
            }),
        }),
        getHighlights: build.mutation<DemoPayload, DemoModel>({
            query: (text) => ({
                url: `gethighlights`,
                method: 'POST',
                body: text,
            }),
        }),
        getShortInfo: build.mutation<DemoPayload, DemoModel>({
            query: (text) => ({
                url: `getprojectinfo`,
                method: 'POST',
                body: text,
            }),
        }),
        getProjects: build.mutation<DemoProjectsPayload, DemoModel>({
            query: (text) => ({
                url: `getprojectlist`,
                method: 'POST',
                body: text,
            }),
        }),
        getProjectsInfo: build.mutation<DemoProjectsInfoPayload, DemoProjectsInfoModel>({
            query: (text) => ({
                url: `getprojectdetails`,
                method: 'POST',
                body: text,
            }),
        }),
        getTables: build.mutation<DemoTablesPayload, DemoModel>({
            query: (text) => ({
                url: `extracttable`,
                method: 'POST',
                body: text,
            }),
        }),
    }),
});

export const {
    useGetBriefMutation,
    useGetTopicsMutation,
    useGetTasksMutation,
    useGetHighlightsMutation,
    useGetShortInfoMutation,
    useGetProjectsMutation,
    useGetProjectsInfoMutation,
    useGetTablesMutation,
} = demoApi;
