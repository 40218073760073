import { RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { RotatingLines } from 'react-loader-spinner';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
    useGetDictionaryQuery,
    useAddDictionaryWordMutation,
    useDeleteDictionaryWordMutation,
    useEditDictionaryWordMutation,
} from 'api/routes/DictionaryApi';
import { ReactComponent as EditIcon } from 'assets/pen-in-square.svg';
import { ReactComponent as PLusIcon } from 'assets/plus.svg';
import { ReactComponent as DeleteIcon } from 'assets/trash-can.svg';
import { ErrorMessage } from 'components/generic/errorMessage/ErrorMessage';
import { Modal, ModalWithDimming } from 'components/generic/modal/Modal';
import SearchBar from 'components/generic/searchBar/SearchBar';

import './Dictionary.scss';

const Dictionary: React.FC = (): JSX.Element => {
    const navigate = useNavigate();
    const { dictId } = useParams();
    const {
        data: dictionary,
        isLoading: isDictionaryLoading,
        error: isDictionaryError,
        isSuccess: isDictionarySuccess,
    } = useGetDictionaryQuery(dictId || '');

    const [addWord] = useAddDictionaryWordMutation();
    const [deleteWord] = useDeleteDictionaryWordMutation();
    const [editWord] = useEditDictionaryWordMutation();

    const [searchText, setSearchText] = useState<string>('');
    const [addingModalIsOpen, setAddingModalIsOpen] = useState<boolean>(false);
    const [editingModalIsOpen, setEditingModalIsOpen] = useState<number | null>(null);
    const wordInputRef = useRef() as RefObject<HTMLInputElement>;
    const [error, setError] = useState<boolean>(false);

    const handleAddWord = useCallback(
        (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();

            if (!wordInputRef.current?.value.length) {
                setError(true);
                return;
            }

            if (wordInputRef.current && dictId) {
                try {
                    addWord({ dict: parseInt(dictId), word: wordInputRef.current.value });
                    setAddingModalIsOpen(false);
                } catch (e) {
                    console.log(e);
                }
            }
        },
        [addWord, dictId],
    );

    const addingWordModal = useCallback(() => {
        return (
            <ModalWithDimming
                hideModal={() => setAddingModalIsOpen(false)}
                modalTitle='Добавить слово'
                modalBody={
                    <form className='adding_dictionary_form' onSubmit={handleAddWord}>
                        <input
                            type='text'
                            className='input'
                            placeholder='Слово'
                            ref={wordInputRef}
                            onChange={() => setError(false)}
                        />
                        {error && <ErrorMessage message={'Введите слово'} />}
                        <button type='submit' className='btn btn_primary'>
                            Добавить
                        </button>
                    </form>
                }
            />
        );
    }, [error, handleAddWord]);

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            word: '',
        },
    });

    const editingWordModal = useCallback(() => {
        return (
            <Modal
                hideModal={() => setEditingModalIsOpen(null)}
                modalTitle='Редактировать слово'
                modalBody={
                    <form
                        className='editing_word_form'
                        onSubmit={handleSubmit((data) => {
                            if (editingModalIsOpen && dictId) {
                                editWord({
                                    wordId: editingModalIsOpen,
                                    word: { dict: parseInt(dictId), word: data.word },
                                })
                                    .unwrap()
                                    .catch((e: any) => {
                                        if (e.status === 403) {
                                            toast.error('Вы не можете изменить данные об этом спикере');
                                        }
                                    });
                                setEditingModalIsOpen(null);
                            }
                        })}
                    >
                        <input {...register('word', { required: 'Обязательное поле' })} className='input' />
                        {errors.word && <ErrorMessage message={errors.word?.message} />}
                        <button type='submit' className='btn btn_primary'>
                            Сохранить
                        </button>
                    </form>
                }
            />
        );
    }, [dictId, editWord, editingModalIsOpen, errors.word, handleSubmit, register]);

    useEffect(() => {
        const currentWord = dictionary?.items.find((el) => el.id === editingModalIsOpen);
        if (currentWord) {
            setValue('word', currentWord.word);
        }
    }, [dictionary?.items, editingModalIsOpen, setValue]);

    return (
        <div className='dictionary_page'>
            <div
                className='back_arrow'
                onClick={() => {
                    navigate('/dict');
                }}
            >
                Назад
            </div>
            <div className='page_title'>
                <h2>{dictionary?.name}</h2>
                <span>{dictionary?.items.length}</span>
            </div>
            <div className='page_container'>
                <div className='search_and_add_line'>
                    <SearchBar defaultText='Поиск' searchText={searchText} setSearchText={setSearchText} />
                    <div className='btn btn_white' onClick={() => setAddingModalIsOpen(true)}>
                        <PLusIcon />
                        <span>Добавить слово</span>
                    </div>
                </div>
                <div className='dictionary_content'>
                    {isDictionaryLoading && (
                        <RotatingLines strokeColor='#814feb' strokeWidth='5' animationDuration='0.75' width='40' />
                    )}
                    {isDictionaryError && (
                        <p style={{ color: 'red' }}>
                            Что-то пошло не так при загрузке словаря.
                            <br />
                            Пожалуйста, попробуйте перезагрузить страницу.
                        </p>
                    )}
                    {isDictionarySuccess && dictionary?.items.length === 0 && <h2>Нет слов</h2>}
                    {isDictionarySuccess &&
                        [...dictionary.items].map((item) => {
                            return (
                                <div className='word_card' key={item.id}>
                                    <div className='name'>{item.word}</div>
                                    <div className='actions'>
                                        {editingModalIsOpen === item.id && editingWordModal()}
                                        <DeleteIcon
                                            className='icon'
                                            onClick={() => {
                                                if (dictId) {
                                                    deleteWord({ dict: dictId, wordId: item.id })
                                                        .unwrap()
                                                        .catch((e: any) => {
                                                            if (e.status === 403) {
                                                                toast.error('Вы не можете удалить это слово');
                                                            }
                                                        });
                                                }
                                            }}
                                        />
                                        <EditIcon
                                            className='icon'
                                            onClick={() => {
                                                setEditingModalIsOpen(item.id);
                                            }}
                                        />
                                    </div>
                                </div>
                            );
                        })}
                </div>
            </div>
            {addingModalIsOpen && addingWordModal()}
        </div>
    );
};

export default Dictionary;
