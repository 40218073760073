import classNames from 'classnames';
import { useRef, useState } from 'react';

import useOutsideClick from 'hooks/useOutsideClick';

interface AudioSpeedDropdownProps {
    value: number;
    valueList: number[];
    handleChangeValue: (value: number) => void;
}

const AudioSpeedDropdown: React.FC<AudioSpeedDropdownProps> = ({
    value,
    valueList,
    handleChangeValue,
}): JSX.Element => {
    const dropdownRef = useRef<HTMLDivElement>(null);
    const [dropdownIsOpen, setDropdownIsOpen] = useState<boolean>(false);

    useOutsideClick([dropdownRef], () => {
        setDropdownIsOpen(false);
    });

    function handleOnClick(item: number) {
        handleChangeValue(item);
        setDropdownIsOpen(false);
    }

    return (
        <>
            <div className='speed_dropdown' ref={dropdownRef}>
                <div
                    tabIndex={0}
                    className='dropdown__header'
                    role='button'
                    onKeyPress={() => setDropdownIsOpen(!dropdownIsOpen)}
                    onClick={() => setDropdownIsOpen(!dropdownIsOpen)}
                >
                    <div className='dropdown__header__title'>{`${value}x`}</div>
                </div>
                <ul className={classNames('dropdown_menu', { 'dropdown_menu--open': dropdownIsOpen })}>
                    {valueList.map((item, i) => (
                        <li className='dropdown_menu__item' key={i} onClick={() => handleOnClick(item)}>
                            <span>{item}</span>
                        </li>
                    ))}
                </ul>
            </div>
        </>
    );
};

export default AudioSpeedDropdown;
