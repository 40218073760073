import { PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { RotatingLines } from 'react-loader-spinner';

import { toast } from 'react-toastify';

import { ViewerProps } from '../DemoProcessing.interface';
import { ShareResultModal } from '../shareResultModal/ShareResultModal';

import { ReactComponent as CloseIcon } from 'assets/cross-bold.svg';
import { ReactComponent as PenIcon } from 'assets/pen.svg';
import { ReactComponent as CopyIcon } from 'assets/transcript/copy.svg';
import { ReactComponent as DownloadIcon } from 'assets/transcript/download.svg';
import { ReactComponent as RollUpIcon } from 'assets/transcript/roll-up.svg';
import { ReactComponent as TraceIcon } from 'assets/transcript/trace.svg';

import { TextEditor } from 'components/views/textEditor/TextEditor';
import { convertHtmlToPlainText } from 'helpers/ConvertHtmlToPlainText';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import { changeDemoView, toggleFullPageProtocol } from 'store/reducers/transcriptions/transcriptAppearanceSlice';

const DemoViewer: React.FC<ViewerProps & PropsWithChildren> = ({
    title,
    startProcessing,
    isRequestSuccess,
    isRequestLoading,
    handleDownload,
    handleShare,
    handleUpdate,
    textToCopy,
    children,
}) => {
    const dispatch = useAppDispatch();
    const { demoTextForCopy, isFullPageProtocol } = useAppSelector((state) => state.transcriptAppearance);

    const text = useMemo(() => children?.toString(), [children]);
    const plainText = useMemo(() => {
        let text = typeof children === 'string' ? children : demoTextForCopy;

        if (textToCopy) {
            text = textToCopy;
        }

        return convertHtmlToPlainText(text);
    }, [children, demoTextForCopy, textToCopy]);

    const [isOpenShareModal, setIsOpenShareModal] = useState(false);
    const [isOpenEditor, setIsOpenEditor] = useState(false);
    const [newText, setNewText] = useState(text);

    useEffect(() => {
        setNewText(text);
    }, [text]);

    const unsecuredCopyToClipboard = useCallback((text: string) => {
        const textArea = document.createElement('textarea');
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
            document.execCommand('copy');
            toast.success('Текст скопирован', { position: 'bottom-right' });
        } catch (err) {
            console.error('Unable to copy to clipboard', err);
        }
        document.body.removeChild(textArea);
    }, []);

    const handleCopyText = useCallback(() => {
        if (!children) {
            return;
        }

        if (window.isSecureContext && navigator.clipboard) {
            navigator.clipboard
                .writeText(plainText ?? '')
                .then((e) => toast.success('Текст скопирован', { position: 'bottom-right' }))
                .catch((e) => console.log(e));
        } else {
            unsecuredCopyToClipboard(plainText ?? '');
        }
    }, [children, plainText, unsecuredCopyToClipboard]);

    return (
        <div className='demo-viewer'>
            <div className='demo-viewer__header'>
                <h2>{title}</h2>
                <div style={{ flex: 1 }}></div>
                <button
                    type='button'
                    className='btn btn_with_icon tooltip'
                    onClick={() => dispatch(toggleFullPageProtocol())}
                >
                    <span>{isFullPageProtocol ? 'Свернуть' : 'Развернуть'}</span>
                    <RollUpIcon />
                    {!isFullPageProtocol ? <span className='tooltip_text'>Развернуть на весь экран</span> : null}
                </button>
                <button className='btn btn_with_icon' onClick={() => dispatch(changeDemoView(null))}>
                    <span>Закрыть</span>
                    <CloseIcon />
                </button>
            </div>
            {isRequestLoading && (
                <div className='demo-viewer__spinner'>
                    <RotatingLines
                        strokeColor='#814feb'
                        strokeWidth='5'
                        animationDuration='0.75'
                        width='40'
                        visible={isRequestLoading}
                    />
                </div>
            )}
            {!isRequestSuccess && !isRequestLoading && (
                <button className='btn btn_primary demo-viewer__create-button' onClick={startProcessing}>
                    Сформировать
                </button>
            )}
            {isRequestSuccess && !isRequestLoading && (
                <>
                    <div className='demo-viewer__text-header'>
                        {handleUpdate ? (
                            <button type='button' className='btn btn_with_icon' onClick={() => setIsOpenEditor(true)}>
                                <span>Редактировать</span>
                                <PenIcon />
                            </button>
                        ) : null}
                        {handleDownload ? (
                            <button type='button' className='btn btn_with_icon' onClick={handleDownload}>
                                <span>Скачать</span>
                                <DownloadIcon />
                            </button>
                        ) : null}
                        <button type='button' className='btn btn_with_icon' onClick={handleCopyText}>
                            <span>Копировать</span>
                            <CopyIcon />
                        </button>
                        {handleShare ? (
                            <button
                                type='button'
                                className='btn btn_with_icon'
                                onClick={() => setIsOpenShareModal(true)}
                            >
                                <span>Поделиться</span>
                                <TraceIcon />
                            </button>
                        ) : null}
                    </div>
                    <div className='demo-viewer__editor'>
                        {handleUpdate ? (
                            <TextEditor text={newText ?? ''} handleChange={setNewText} readOnly={!isOpenEditor} />
                        ) : (
                            children
                        )}
                        <div className='demo-viewer__editor__button-container'>
                            {isOpenEditor ? (
                                <>
                                    <button
                                        className='btn _white'
                                        onClick={() => {
                                            setIsOpenEditor(false);
                                            setNewText(text);
                                        }}
                                    >
                                        Отменить
                                    </button>
                                    <button
                                        className='btn btn_primary'
                                        onClick={() => handleUpdate?.(newText ?? '').then(() => setIsOpenEditor(false))}
                                    >
                                        Сохранить
                                    </button>
                                </>
                            ) : (
                                <button
                                    className='btn btn_primary demo-viewer__create-button'
                                    onClick={startProcessing}
                                >
                                    Сформировать повторно
                                </button>
                            )}
                        </div>
                    </div>
                </>
            )}
            {isOpenShareModal && handleShare ? (
                <ShareResultModal
                    hideModal={() => setIsOpenShareModal(false)}
                    onSubmit={(emails) => handleShare(emails, plainText ?? '').then(() => setIsOpenShareModal(false))}
                />
            ) : null}
        </div>
    );
};

export default DemoViewer;
