import { createApi } from '@reduxjs/toolkit/dist/query/react';

import { customBaseQuery } from 'api/query';

import {
    ExchangeShareTokenReponse,
    RefreshTokenResponse,
    ShareTranscriptResponse,
    ShareTranscriptPayload,
    RegisterCredentials,
    // TODO: TAS-1007
    // UserBalanceInfoPayload,
} from 'api/types/controllers';
import { getJWTToken } from 'helpers/LocalStorageActions';
import { UserJuridicalInfo, UserInfo } from 'types/models';

const REACT_APP_API_HOST = process.env.REACT_APP_API_HOST;

export const appApi = createApi({
    reducerPath: 'appApi',
    tagTypes: ['UserInfo', 'UserJuridicalInfo'],
    baseQuery: customBaseQuery({ baseUrl: `${REACT_APP_API_HOST}/api/`, requiresAuth: false, enableReauth: true }),
    endpoints: (build) => ({
        login: build.mutation({
            query: (body) => ({
                url: 'login/',
                method: 'POST',
                body,
            }),
            invalidatesTags: ['UserInfo'],
        }),
        getApiToken: build.query({
            query: () => ({
                url: 'api_token/',
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${getJWTToken()}`,
                },
            }),
            transformResponse: (response: { token: string }) => response.token,
        }),
        refreshJwtToken: build.mutation<string, { refresh: string }>({
            query: (body) => ({
                url: 'refresh_token/',
                method: 'POST',
                body,
            }),
            transformResponse: (response: RefreshTokenResponse) => response.access,
        }),
        register: build.mutation<boolean, RegisterCredentials>({
            query: (body) => ({
                url: 'register/',
                method: 'POST',
                body,
            }),
        }),
        confirmAccount: build.query<boolean, string>({
            query: (token) => ({
                url: `confirm/${token}/`,
                method: 'GET',
            }),
            providesTags: ['UserInfo'],
        }),
        resendEmail: build.mutation({
            query: (body) => ({
                url: 'resend_email_token/',
                method: 'POST',
                body,
            }),
        }),
        resetPassword: build.mutation({
            query: (body) => ({
                url: 'password_reset/',
                method: 'POST',
                body,
            }),
        }),
        changePassword: build.mutation({
            query: (body) => ({
                url: 'password_change/',
                method: 'POST',
                body,
                headers: body.old_password
                    ? {
                          Authorization: `Bearer ${getJWTToken()}`,
                      }
                    : {},
            }),
        }),
        getUserInfo: build.query<UserInfo, void>({
            query: () => ({
                url: 'user_info/',
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${getJWTToken()}`,
                },
            }),
            providesTags: ['UserInfo'],
        }),
        changeUserInfo: build.mutation({
            query: (body) => ({
                url: 'user_info/',
                method: 'PUT',
                headers: {
                    Authorization: `Bearer ${getJWTToken()}`,
                },
                body,
            }),
            invalidatesTags: ['UserInfo'],
        }),
        getJuridicalUserInfo: build.query<UserJuridicalInfo, string>({
            query: () => ({
                url: 'juridical_person_info/',
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${getJWTToken()}`,
                },
            }),
            providesTags: ['UserJuridicalInfo'],
        }),
        changeJuridicalUserInfo: build.mutation({
            query: (body) => ({
                url: 'juridical_person_info/',
                method: 'PUT',
                headers: {
                    Authorization: `Bearer ${getJWTToken()}`,
                },
                body,
            }),
            invalidatesTags: ['UserJuridicalInfo'],
        }),
        postFeedback: build.mutation({
            query: (body) => ({
                url: 'feedback/',
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${getJWTToken()}`,
                },
                body,
            }),
        }),
        // TODO: TAS-1007
        // getUserBalanceInfo: build.query<UserBalanceInfoResponse, null>({
        //     query: () => ({
        //         url: 'payment/balance/',
        //         method: 'GET',
        //         headers: {
        //             Authorization: `Bearer ${getJWTToken()}`,
        //         },
        //     }),
        // }),
        shareTranscript: build.mutation<ShareTranscriptResponse, ShareTranscriptPayload>({
            query: (body) => ({
                url: 'share/add/',
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${getJWTToken()}`,
                },
                body,
            }),
        }),
        exchangeShareToken: build.query<ExchangeShareTokenReponse[], string>({
            query: (token) => ({
                url: `share/${token}/`,
                method: 'GET',
            }),
        }),
    }),
});

export const {
    useResetPasswordMutation,
    useLazyGetApiTokenQuery,
    useLoginMutation,
    useRegisterMutation,
    useConfirmAccountQuery,
    useChangePasswordMutation,
    // TODO: TAS-1007
    // useGetUserBalanceInfoQuery,
    // useLazyGetUserBalanceInfoQuery,
    useShareTranscriptMutation,
    useLazyExchangeShareTokenQuery,
} = appApi;
