import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { recordsApi } from 'api/routes/RecordsApi';
import { requestsApi } from 'api/routes/RequestsApi';
import { resultsApi } from 'api/routes/ResultsApi';
import { RequestFilterParams } from 'types/common';
import { RequestType, SearchTranscript, Transcript } from 'types/models';
interface ArchiveState {
    records: Transcript[] | SearchTranscript[];
    recordsCount: number;
    requests: RequestType[];
    isFiltered: boolean;
    filteredDate: Partial<RequestFilterParams>;
    isLoading: boolean;
}

const slice = createSlice({
    name: 'archive',
    initialState: {
        records: [],
        recordsCount: 0,
        requests: [],
        isFiltered: false,
        filteredDate: {
            timestamp_after: undefined,
            timestamp_before: undefined,
        },
        isLoading: false,
    } as ArchiveState,
    reducers: {
        setFilteredDate: (state, { payload: date }: PayloadAction<[string, string] | null>) => {
            state.filteredDate = {
                timestamp_after: date ? new Date(date[0]).toISOString() : undefined,
                timestamp_before: date ? new Date(date[1]).toISOString() : undefined,
            };
        },
        setIsFiltered: (state, { payload: isFiltered }: PayloadAction<boolean>) => {
            state.isFiltered = isFiltered;
        },
        setRecords: (
            state,
            {
                payload: { records, count },
            }: PayloadAction<{ records: Transcript[] | SearchTranscript[]; count: number }>,
        ) => {
            state.records = records;
            state.recordsCount = count;
        },
    },
    extraReducers: (builder) => {
        const loadingState = {
            isLoadingSearch: false,
            isLoadingFiles: false,
            isLoadingRequests: false,
            isLoadingResults: false,
            isLoadingFilteredResults: false,
        };
        const setLoading = (state: ArchiveState) => {
            state.isLoading = Object.values(loadingState).some((isLoading) => isLoading);
        };
        builder.addMatcher(resultsApi.endpoints.results.matchFulfilled, (state, { payload }) => {
            state.records = payload.results;
            state.recordsCount = payload.count;
            state.isFiltered = !!(state.filteredDate.timestamp_after && state.filteredDate.timestamp_before);
            loadingState.isLoadingResults = false;
            setLoading(state);
        });
        builder.addMatcher(requestsApi.endpoints.requests.matchFulfilled, (state, { payload }) => {
            state.requests = payload.results.filter(
                (item) => item.task_status && item.task_status !== 'success' && item.task_status !== 'revoked',
            );
            loadingState.isLoadingRequests = false;
            setLoading(state);
        });
        builder.addMatcher(resultsApi.endpoints.search.matchFulfilled, (state, { payload }) => {
            state.recordsCount = payload.count;
            state.records = payload.results;
            state.isFiltered = true;
            loadingState.isLoadingSearch = false;
            setLoading(state);
        });
        builder.addMatcher(recordsApi.endpoints.getFiles.matchFulfilled, (state) => {
            loadingState.isLoadingFiles = false;
            setLoading(state);
        });
        builder.addMatcher(resultsApi.endpoints.search.matchPending, (state) => {
            state.isLoading = true;
            loadingState.isLoadingSearch = true;
        });
        builder.addMatcher(recordsApi.endpoints.getFiles.matchPending, (state) => {
            state.isLoading = true;
            loadingState.isLoadingFiles = true;
        });
        builder.addMatcher(requestsApi.endpoints.requests.matchPending, (state) => {
            state.isLoading = true;
            loadingState.isLoadingRequests = true;
        });
        builder.addMatcher(resultsApi.endpoints.results.matchPending, (state) => {
            state.isLoading = true;
            loadingState.isLoadingResults = true;
        });
    },
});

export const { setFilteredDate, setIsFiltered, setRecords } = slice.actions;
export default slice.reducer;
