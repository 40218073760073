import './ProgressBar.scss';

interface ProgressBarProps {
    progress: number;
    animated?: boolean;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ progress, animated }) => (
    <div className='progressbar_wrapper'>
        <div className='progressbar-complete' style={{ width: `${progress <= 100 ? progress : 100}%` }}>
            {animated && <div className='progressbar-liquid'></div>}
        </div>
    </div>
);

export default ProgressBar;
