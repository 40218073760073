import { useCallback, useMemo, useState, useEffect } from 'react';
import { RotatingLines } from 'react-loader-spinner';
import { toast } from 'react-toastify';

import { SimpleTranscriptShareProps } from './TranscriptShare.interface';

import { useShareTranscriptMutation } from 'api/routes/AppApi';

// import { ReactComponent as ArrowIcon } from 'assets/arrow-without-body.svg';
import { ReactComponent as CopyIcon } from 'assets/transcript/copy.svg';
import { ModalWithDimming } from 'components/generic/modal/Modal';

import './TranscriptShare.scss';
import { SHARE_GRANT, SHARE_LAVEL } from 'constants/common';
import { SHARE_GRANT_DESCRIPTION } from 'constants/messages';

// закомментировано до реализации на бэке
// const roleOptions = [
//     { value: ShareGrant.Read, label: SHARE_GRANT_LABEL[ShareGrant.Read] },
//     { value: ShareGrant.Comment, label: SHARE_GRANT_LABEL[ShareGrant.Comment] },
//     { value: ShareGrant.Edit, label: SHARE_GRANT_LABEL[ShareGrant.Edit] },
// ];

const SimpleTranscriptShare: React.FC<SimpleTranscriptShareProps> = ({
    transcriptId,
    fileId,
    hideModal,
    savedShareToken,
    setSavedShareToken,
}) => {
    const [createLink, { isLoading: isCreateLinkLoading }] = useShareTranscriptMutation();

    // const [isRoleDropdownOpen, setIsRoleDropdownOpen] = useState<boolean>(false);
    const [selectedLevel] = useState<SHARE_LAVEL>(SHARE_LAVEL.Anonymous);
    const [selectedRole] = useState<SHARE_GRANT>(SHARE_GRANT.Read);
    const [shareToken, setShareToken] = useState<string | null>(savedShareToken || null);

    // const dropdownRef = useRef<HTMLDivElement>(null);

    const shareLink = useMemo(
        () => (shareToken ? `${window.location.protocol}//${window.location.host}/share/transcript/${shareToken}` : ''),
        [shareToken],
    );

    // useOutsideClick([dropdownRef], () => {
    //     setIsRoleDropdownOpen(false);
    // });

    const unsecuredCopyToClipboard = useCallback(() => {
        const textArea = document.createElement('textarea');
        textArea.value = shareLink;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
            document.execCommand('copy');
            toast.success('Ссылка скопирована', { position: 'bottom-right' });
        } catch (err) {
            console.error('Unable to copy to clipboard', err);
        }
        document.body.removeChild(textArea);
    }, [shareLink]);

    const handleCopyLink = useCallback(() => {
        if (!shareLink) {
            return;
        }

        if (window.isSecureContext && navigator.clipboard) {
            navigator.clipboard
                .writeText(shareLink)
                .then((e) => toast.success('Ссылка скопирована', { position: 'bottom-right' }))
                .catch((e) => console.log(e));
        } else {
            unsecuredCopyToClipboard();
        }
    }, [shareLink, unsecuredCopyToClipboard]);

    const updateShareToken = useCallback(() => {
        createLink({
            level: selectedLevel,
            grant: selectedRole,
            objects: [
                {
                    system_unit: 'api',
                    object_id: transcriptId || '',
                },
                {
                    system_unit: 'records',
                    object_id: fileId || '',
                },
            ],
        })
            .unwrap()
            .then((data) => {
                setShareToken(data.public_token);
                if (setSavedShareToken) {
                    setSavedShareToken(data.public_token);
                }
            })
            .catch((e) => {
                console.log(e);
                setShareToken(null);
                if (setSavedShareToken) {
                    setSavedShareToken(null);
                }
            });
    }, [createLink, fileId, selectedLevel, selectedRole, setSavedShareToken, transcriptId]);

    useEffect(() => {
        if (!shareLink) {
            updateShareToken();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ModalWithDimming
            hideModal={hideModal}
            modalTitle='Поделиться'
            modalBodyClassname='share-modal__body'
            modalTitleClassname='share-modal__title'
            modalBody={
                <form
                    className='share-modal'
                    onSubmit={(e) => {
                        e.preventDefault();
                        updateShareToken();
                    }}
                >
                    {/* закомментировано до реализации на бэке */}
                    {/* <div className='share-modal__role'>
                        <span>Права доступа по ссылке:</span>
                        <div ref={dropdownRef} className='share-modal__role__dropdown'>
                            <div
                                tabIndex={0}
                                className='dropdown__header input'
                                role='button'
                                onKeyPress={() => setIsRoleDropdownOpen(!isRoleDropdownOpen)}
                                onClick={() => setIsRoleDropdownOpen(!isRoleDropdownOpen)}
                            >
                                <div className='dropdown__header__title'>{SHARE_GRANT_LABEL[selectedRole]}</div>
                                <ArrowIcon />
                            </div>
                            <ul className={classNames('dropdown_menu', { 'dropdown_menu--open': isRoleDropdownOpen })}>
                                {roleOptions.map(({ value, label }, i) => (
                                    <li
                                        className='dropdown_menu__item'
                                        key={i}
                                        onClick={() => {
                                            setSelectedRole(value);
                                            setIsRoleDropdownOpen(false);
                                        }}
                                    >
                                        <span>{label}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    {selectedRole && <p>{SHARE_GRANT_DESCRIPTION[selectedRole]}</p>} */}
                    <div className='share-modal__create-link'>
                        <div>
                            <label>
                                Ссылка на запись
                                <input type='text' className='input' readOnly value={shareLink} />
                            </label>
                            <button type='button' className='btn btn_with_icon btn_secondary' onClick={handleCopyLink}>
                                <CopyIcon />
                            </button>
                        </div>
                        {selectedRole && <p>{SHARE_GRANT_DESCRIPTION[selectedRole]}</p>}
                        <button type='submit' className='btn btn_primary'>
                            {isCreateLinkLoading ? (
                                <RotatingLines
                                    strokeColor='white'
                                    strokeWidth='5'
                                    animationDuration='0.75'
                                    width='30'
                                />
                            ) : (
                                'Обновить'
                            )}
                        </button>
                    </div>
                </form>
            }
        />
    );
};

export default SimpleTranscriptShare;
