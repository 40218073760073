import { useAppSelector } from 'hooks/useAppSelector';

interface ArchiveHeaderProps {}

const ArchiveHeader: React.FC<ArchiveHeaderProps> = () => {
    const { recordsCount } = useAppSelector((state) => state.archive);

    return (
        <p className='page_title'>
            Мои записи
            <span className='records_number'>{recordsCount}</span>
        </p>
    );
};

export default ArchiveHeader;
