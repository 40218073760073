import { DemoTable } from 'api/types/controllers';

export const convertDemoTableToString = (data: DemoTable[]): string => {
    let string = '';

    data.forEach((item) => {
        string += `${item.name}\n`;
        string += `${item.data.columns.join(' ')}\n`;

        item.data.rows.forEach((row) => {
            string += `${row.join(' ')}\n`;
        });

        string += '\n';
    });

    return string;
};
