import { createApi } from '@reduxjs/toolkit/dist/query/react';

import { resultsApi } from './ResultsApi';

import { customBaseQuery } from 'api/query';
import { FilesPayload, FileUploadResultResponse, RenameFilePayload } from 'api/types/controllers';
import { getAPIToken } from 'helpers/LocalStorageActions';
import { FileType } from 'types/models';

const RECORDS_API_HOST = process.env.REACT_APP_RECORDS_API_HOST;

export const recordsApi = createApi({
    reducerPath: 'recordsApi',
    baseQuery: customBaseQuery({ baseUrl: `${RECORDS_API_HOST}/api/` }),
    tagTypes: ['Files'],
    endpoints: (build) => {
        return {
            getFiles: build.query<FilesPayload, null>({
                query: () => ({
                    url: '/files/',
                    method: 'GET',
                }),
                providesTags: ['Files'],
            }),
            getFilesByIds: build.mutation<FilesPayload, string[]>({
                query: (fileIds) => ({
                    url: `files/?limit=${fileIds.length}`,
                    method: 'POST',
                    body: fileIds,
                }),
            }),
            getFileMetadata: build.query<FileType, { fileId: string; shareAccessToken?: string }>({
                query: ({ fileId, shareAccessToken }) => ({
                    url: `file/metadata/${fileId}/`,
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${shareAccessToken || getAPIToken()}`,
                    },
                }),
            }),
            uploadFile: build.mutation<FileUploadResultResponse, FormData>({
                query: (formData) => ({
                    url: '/file/upload/',
                    method: 'PUT',
                    body: formData,
                }),
            }),
            renameFile: build.mutation<FileType, RenameFilePayload>({
                query: ({ file_id, user_path }) => ({
                    url: `file/rename/${file_id}/`,
                    method: 'PUT',
                    body: { user_path },
                }),
                invalidatesTags: ['Files'],
                async onQueryStarted(_, { dispatch, queryFulfilled }) {
                    await queryFulfilled;
                    dispatch(resultsApi.util.invalidateTags(['Result']));
                },
            }),
            deleteFile: build.mutation<boolean, string>({
                query: (id) => ({
                    url: `file/delete/${id}/`,
                    method: 'DELETE',
                    body: id,
                }),
                invalidatesTags: ['Files'],
                async onQueryStarted(_, { dispatch, queryFulfilled }) {
                    await queryFulfilled;
                    dispatch(resultsApi.util.invalidateTags(['Results', 'Result']));
                },
            }),
            downloadFileById: build.query<any, { fileId: string; shareAccessToken?: string }>({
                query: ({ fileId, shareAccessToken }) => ({
                    url: `file/download/${fileId}/`,
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${shareAccessToken || getAPIToken()}`,
                    },
                    responseHandler: async (response) => {
                        let blob = await response.blob();
                        const downloadUrl = window.URL.createObjectURL(blob);
                        const link = document.createElement('a');
                        link.href = downloadUrl;
                        // IMPORTANT FIX заменить на название стенограммы
                        link.download = `file_${Date.now()}.mp3`;
                        document.body.appendChild(link);
                        link.click();
                        link.remove();
                    },
                }),
            }),
            downloadProtocolById: build.query<any, { fileId: string; name: string }>({
                query: ({ fileId, name }) => ({
                    url: `file/download/protocol/${fileId}/`,
                    method: 'GET',
                    cache: 'no-cache',
                    responseHandler: async (response) => {
                        let blob = await response.blob();
                        const downloadUrl = window.URL.createObjectURL(blob);
                        const link = document.createElement('a');
                        link.href = downloadUrl;
                        link.download = `${name}.docx`;
                        document.body.appendChild(link);
                        link.click();
                        link.remove();
                    },
                }),
            }),
        };
    },
});

export const {
    useGetFilesQuery,
    useGetFilesByIdsMutation,
    useRenameFileMutation,
    useLazyGetFileMetadataQuery,
    useLazyGetFilesQuery,
    useUploadFileMutation,
    useDeleteFileMutation,
    useLazyDownloadFileByIdQuery,
    useLazyDownloadProtocolByIdQuery,
} = recordsApi;
