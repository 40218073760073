import { useState, useEffect, useRef, RefObject, useCallback, useMemo } from 'react';
import { RotatingLines } from 'react-loader-spinner';

import AudioSpeedDropdown from './AudioSpeedDropdown';
import { PlayerProps } from './Player.interface';

import FormattedTime from 'components/generic/formattedTime/FormattedTime';
import { MILLISECONDS_PER_SECOND } from 'constants/common';

import './Player.scss';

const Player: React.FC<PlayerProps> = ({
    audioUrl,
    audioFragment,
    currentAudioTimestamp,
    audioSpeedChoose,
    duration,
    preload,
}) => {
    const audioRef = useRef() as RefObject<HTMLAudioElement>;

    const [isPlaying, setIsPlaying] = useState(false);
    const [isWaiting, setIsWaiting] = useState<boolean>(false);
    const [currentAudioTime, setCurrentAudioTime] = useState(audioFragment?.startTime || 0);
    const [audioSpeed, setAudioSpeed] = useState<number>(1);

    const changeCurrentTime = useCallback(
        (value: number) => {
            if (!audioRef.current) {
                return;
            }

            if (audioRef.current.ended || audioRef.current.duration !== Infinity) {
                audioRef.current.load();
                audioRef.current.currentTime = value;
                audioRef.current.playbackRate = audioSpeed;
                isPlaying ? audioRef.current?.play() : audioRef.current?.pause();
            } else {
                audioRef.current.currentTime = value;
            }

            if (audioRef.current?.readyState === 0 || audioRef.current?.readyState === 1) {
                setIsWaiting(true);
            }
        },
        [audioSpeed, isPlaying],
    );

    // перемотка аудио при нажатии на слово стенограммы
    useEffect(() => {
        if (currentAudioTimestamp && audioRef.current) {
            changeCurrentTime(currentAudioTimestamp);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentAudioTimestamp]);

    useEffect(() => {
        const audio = audioRef.current;

        const action = () => {
            setIsPlaying(false);
            audioFragment?.setIsFragmentPlaying(false);
        };
        const events = ['ended', 'pause'];

        events.forEach((event) => {
            audio?.addEventListener(event, action);
        });

        return () => {
            events.forEach((event) => {
                audio?.removeEventListener(event, action);
            });
        };
    }, [audioFragment]);

    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.playbackRate = audioSpeed;
        }
    }, [audioSpeed]);

    useEffect(() => {
        if (audioFragment) {
            setIsPlaying(audioFragment.isFragmentPlaying);
        }
    }, [audioFragment, audioFragment?.isFragmentPlaying]);

    useEffect(() => {
        if (audioRef.current && audioFragment?.startTime && audioFragment?.isFragmentPlaying) {
            changeCurrentTime(audioFragment.startTime);
        }
    }, [audioFragment?.startTime, audioFragment?.isFragmentPlaying, changeCurrentTime]);

    useEffect(() => {
        isPlaying ? audioRef.current?.play() : audioRef.current?.pause();
    }, [isPlaying]);

    const togglePlaying = useCallback(() => {
        setIsPlaying(!isPlaying);
        if (audioRef.current?.ended) {
            audioRef.current?.load();
            audioRef.current.playbackRate = audioSpeed;
        }

        if (audioFragment && audioFragment?.isFragmentPlaying) {
            audioFragment.setIsFragmentPlaying(false);
        }
    }, [audioFragment, audioSpeed, isPlaying]);

    const onRangeChange = useCallback(
        (e: any) => {
            changeCurrentTime(e.target.value / MILLISECONDS_PER_SECOND);
        },
        [changeCurrentTime],
    );

    const Audio = useMemo(
        () => (
            <audio
                preload={preload ? 'auto' : 'none'}
                ref={audioRef}
                src={audioUrl}
                onTimeUpdate={(e) => {
                    setCurrentAudioTime(e.currentTarget.currentTime * MILLISECONDS_PER_SECOND);

                    if (!(audioRef.current?.readyState === 0 || audioRef.current?.readyState === 1)) {
                        setIsWaiting(false);
                    }

                    if (
                        audioFragment?.isFragmentPlaying &&
                        audioFragment?.endTime &&
                        e.currentTarget.currentTime >= audioFragment?.endTime
                    ) {
                        setIsPlaying(false);
                        audioFragment.setIsFragmentPlaying(false);
                    }
                }}
            >
                Your browser does not support the audio element.
            </audio>
        ),
        [audioFragment, audioUrl, preload],
    );

    return (
        <div className='player'>
            <div className='play_button_container play_button_container--light' onClick={togglePlaying}>
                <div className={isPlaying ? 'play_button--pause' : 'play_button--play'}></div>
            </div>
            {audioSpeedChoose && (
                <AudioSpeedDropdown
                    value={audioSpeed}
                    valueList={audioSpeedChoose}
                    handleChangeValue={(value: number) => {
                        setAudioSpeed(value);
                    }}
                />
            )}
            {Audio}
            <p className='audio_current_time'>
                {isWaiting ? (
                    <RotatingLines strokeColor='#814feb' strokeWidth='5' animationDuration='0.75' width='20' />
                ) : (
                    <FormattedTime duration={currentAudioTime} />
                )}
            </p>
            <input
                type='range'
                step={0.01}
                className='audio_range'
                onChange={onRangeChange}
                value={currentAudioTime}
                min={0}
                max={duration}
            />
            <p className='audio_duration'>
                <FormattedTime duration={duration} />
            </p>
        </div>
    );
};

export default Player;
