import React, { useCallback } from 'react';

import { SearchBarProps } from './SearchBar.interfaces';

import { ReactComponent as ClearIcon } from 'assets/cross-in-circle.svg';
import { ReactComponent as SearchIcon } from 'assets/loupe.svg';

import './SearchBar.scss';

const SearchBar: React.FC<SearchBarProps> = ({ defaultText, searchText, setSearchText, onPressEnter }): JSX.Element => {
    const handleClear = useCallback((): void => {
        setSearchText('');
    }, [setSearchText]);

    return (
        <div className='search_bar'>
            <SearchIcon className='search_icon' />

            <input
                className='search_input'
                type='text'
                placeholder={defaultText}
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                onKeyDown={(e) => {
                    if (onPressEnter && e.code === 'Enter') {
                        onPressEnter(e.target.value);
                    }
                }}
            />

            {searchText.length > 0 && <ClearIcon className='clear_icon' onClick={handleClear} />}
        </div>
    );
};

export default SearchBar;
