import Header from './header/Header';
import Landing from './landing-sections/Landing';
import Menu from './menu-sections/Menu';

import { isLargeMobile } from 'config/env';

import './MainPage.scss';

const MainPage: React.FC = () => (
    <>
        <Header />
        <div className='main_page'>
            {!isLargeMobile && <div className='curtains'></div>}
            <div className='sections_container'>
                <Landing />
                {!isLargeMobile && <Menu />}
            </div>
        </div>
    </>
);

export default MainPage;
