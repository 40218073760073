import { createApi } from '@reduxjs/toolkit/query/react';

import { customBaseQuery } from 'api/query';
import {
    EditResultPayload,
    ResultPayload,
    ResultsResponse,
    SearchResultsResponse,
    ShareProtocolPayload,
} from 'api/types/controllers';
import { getAPIToken } from 'helpers/LocalStorageActions';
import { RequestParams } from 'types/common';
import { Transcript, TranscriptSummaries } from 'types/models';

const API_API_HOST = process.env.REACT_APP_API_API_HOST;

export const resultsApi = createApi({
    reducerPath: 'resultsApi',
    tagTypes: ['Results', 'Result'],
    baseQuery: customBaseQuery({ baseUrl: `${API_API_HOST}/api/` }),
    endpoints: (build) => {
        return {
            results: build.query<ResultsResponse, Partial<RequestParams>>({
                query: (params) => ({
                    url: `results/?success=true`,
                    method: 'GET',
                    params,
                }),
                providesTags: [{ type: 'Results' }],
            }),
            result: build.query<Transcript, ResultPayload>({
                query: ({ transcriptId, shareAccessToken, version }) => ({
                    url: `result/${transcriptId}/`,
                    method: 'GET',
                    params: { version },
                    headers: {
                        Authorization: `Bearer ${shareAccessToken || getAPIToken()}`,
                    },
                }),
                providesTags: [{ type: 'Result' }],
            }),
            editResult: build.mutation<
                Transcript,
                { req_id: string; result: EditResultPayload; shareAccessToken?: string }
            >({
                query: ({ req_id, result, shareAccessToken }) => ({
                    url: `result/${req_id}/`,
                    method: 'PUT',
                    body: result,
                    headers: {
                        Authorization: `Bearer ${shareAccessToken || getAPIToken()}`,
                    },
                }),
                invalidatesTags: [{ type: 'Result' }],
            }),
            search: build.query<SearchResultsResponse, { searchString: string } & Partial<RequestParams>>({
                query: ({ searchString, ...params }) => ({
                    url: `search/${searchString}/`,
                    method: 'GET',
                    params,
                }),
            }),
            getProtocol: build.query<{ summaries: TranscriptSummaries; summarize_timestamp: string }, string>({
                query: (req_id) => ({
                    url: `result/${req_id}/`,
                    method: 'GET',
                }),
                transformResponse: (response: Transcript) => ({
                    summaries: response.summaries,
                    summarize_timestamp: response.summarize_timestamp,
                }),
            }),
            shareProtocol: build.mutation<void, ShareProtocolPayload>({
                query: (body) => ({
                    url: `send_emails/`,
                    method: 'POST',
                    body: body,
                }),
            }),
            downloadTranscriptFile: build.query<
                any,
                { req_id: string; doc_format: string; name: string; shareAccessToken?: string }
            >({
                query: ({ req_id, doc_format, name, shareAccessToken }) => ({
                    url: `result/${req_id}/download/${doc_format}/`,
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${shareAccessToken || getAPIToken()}`,
                    },
                    responseHandler: async (response) => {
                        if (response.status === 200) {
                            let blob = await response.blob();
                            const downloadUrl = window.URL.createObjectURL(blob);
                            const link = document.createElement('a');
                            link.href = downloadUrl;
                            link.download = `${name}.${doc_format}`;
                            document.body.appendChild(link);
                            link.click();
                            link.remove();
                        }
                    },
                }),
            }),
            downloadListOfTasks: build.query<any, {}>({
                // Изменить после появления запроса на скачивание списка задач
                query: () => ({
                    url: ``,
                    method: 'GET',
                    responseHandler: async (response) => {
                        if (response.status === 200) {
                            let blob = await response.blob();
                            const downloadUrl = window.URL.createObjectURL(blob);
                            const link = document.createElement('a');
                            link.href = downloadUrl;
                            link.download = 'Tasks.docs';
                            document.body.appendChild(link);
                            link.click();
                            link.remove();
                        }
                    },
                }),
            }),
        };
    },
});

export const {
    useResultsQuery,
    useLazyResultsQuery,
    useLazyResultQuery,
    useEditResultMutation,
    useLazyGetProtocolQuery,
    useLazyDownloadTranscriptFileQuery,
    useLazySearchQuery,
    useShareProtocolMutation,
    useLazyDownloadListOfTasksQuery,
} = resultsApi;
