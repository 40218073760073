import { useMemo } from 'react';

import {
    secToDefaultTimeFormatDivided,
    msToDefaultTimeFormatDivided,
    secToDefaultTimeFormat,
    msToDefaultTimeFormat,
} from 'helpers/TimeFormatting';

const FormattedTime: React.FC<{ duration: number; isSec?: boolean }> = ({ duration, isSec = false }): JSX.Element => {
    const dividedTime = useMemo(
        () => (isSec ? secToDefaultTimeFormatDivided(duration) : msToDefaultTimeFormatDivided(duration)),
        [duration, isSec],
    );

    const time = useMemo(
        () => (isSec ? secToDefaultTimeFormat(duration) : msToDefaultTimeFormat(duration)),
        [duration, isSec],
    );

    const timeElementRenderer = useMemo(() => {
        let timeElement: JSX.Element;

        if (parseInt(dividedTime[0]) !== 0) {
            timeElement = (
                <span>
                    <b>{time}</b>
                </span>
            );
        } else if (parseInt(dividedTime[1]) !== 0) {
            timeElement = (
                <span>
                    {dividedTime[0] + ':'}
                    <b>{dividedTime[1] + ':' + dividedTime[2]}</b>
                </span>
            );
        } else if (parseInt(dividedTime[2]) !== 0) {
            timeElement = (
                <span>
                    {dividedTime[0] + ':' + dividedTime[1] + ':'}
                    <b>{dividedTime[2]}</b>
                </span>
            );
        } else {
            timeElement = <span>{time}</span>;
        }

        return timeElement;
    }, [dividedTime, time]);

    return timeElementRenderer;
};

export default FormattedTime;
