import { FC, useState } from 'react';

import { AddEmailForm } from './addEmailForm/AddEmailForm';
import { ShareResultModalProps } from './ShareResultModal.interface';

import { ReactComponent as CrossIcon } from 'assets/cross.svg';
import { ModalWithDimming } from 'components/generic/modal/Modal';

import './ShareResultModal.scss';

export const ShareResultModal: FC<ShareResultModalProps> = ({ hideModal, onSubmit }) => {
    const [emails, setEmails] = useState<string[]>([]);

    const addEmail = (email: string) => {
        if (email && !emails.includes(email)) {
            setEmails([...emails, email]);
        }
    };

    const removeEmail = (emailToRemove: string) => {
        setEmails(emails.filter((e) => e !== emailToRemove));
    };

    const handleSubmit = () => {
        onSubmit(emails);
    };

    return (
        <ModalWithDimming
            hideModal={hideModal}
            modalTitle='Поделиться'
            modalBodyClassname='share-result-modal__body'
            modalTitleClassname='share-result-modal__title'
            modalBody={
                <div className='share-result-modal'>
                    <AddEmailForm addEmail={addEmail} />
                    <div className='share-result-modal__emails-container'>
                        {emails.length > 0 ? <span>Кому: </span> : null}
                        {emails.map((item, index) => (
                            <div key={index} className='share-result-modal__email'>
                                <span>{item}</span>
                                <button
                                    type='button'
                                    onClick={() => removeEmail(item)}
                                    className='btn btn_with_icon btn_light'
                                >
                                    <CrossIcon />
                                </button>
                            </div>
                        ))}
                    </div>
                    <p>Протокол будет отправлен на указанные email-адреса.</p>
                    <div className='share-result-modal__submit'>
                        <button className='btn btn_primary' onClick={handleSubmit} disabled={emails.length === 0}>
                            Поделиться
                        </button>
                    </div>
                </div>
            }
        ></ModalWithDimming>
    );
};
