import { endOfDay, startOfDay } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';

import Calendar from './calendar/Calendar';

// TODO: TAS-1007
// import { useLazyGetUserBalanceInfoQuery } from 'api/routes/AppApi';
import { useLazyGetFilesQuery } from 'api/routes/RecordsApi';
import { useLazyRequestsQuery } from 'api/routes/RequestsApi';
import { useLazySearchQuery, useLazyResultsQuery } from 'api/routes/ResultsApi';
import SearchBar from 'components/generic/searchBar/SearchBar';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import useSortParams from 'hooks/useSortParams';
import { setFilteredDate, setIsFiltered } from 'store/reducers/archive/archiveSlice';
import { setOffset } from 'store/reducers/pagination/paginationSlice';
import { setArchiveFilterWord } from 'store/reducers/transcriptions/transcriptSlice';

import './SearchFilter.scss';

const SearchFilter: React.FC = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const pagination = useAppSelector((store) => store.pagination);
    const sortParams = useSortParams();

    const [searchDate, setSearchDate] = useState<[string, string] | null>(null);
    const [searchDateRange, setSearchDateRange] = useState<[string, string] | null>(null);
    const [searchText, setSearchText] = useState<string>('');
    const [getSearchResult] = useLazySearchQuery();
    const [getAllRecords] = useLazyResultsQuery();
    const [getAllRequests] = useLazyRequestsQuery();
    const [getAllFiles] = useLazyGetFilesQuery();
    // TODO: TAS-1007
    // const [getBalance] = useLazyGetUserBalanceInfoQuery();

    const handleSearchDate = useCallback((date: Date | null) => {
        setSearchDate(date && [startOfDay(date).toISOString(), endOfDay(date).toISOString()]);
    }, []);

    const handleSearchDateRange = useCallback((date: [Date, Date] | null) => {
        setSearchDateRange(date && [startOfDay(date[0]).toISOString(), endOfDay(date[1]).toISOString()]);
    }, []);

    useEffect(() => {
        dispatch(setOffset(0));
    }, [searchText, searchDateRange, searchDate, dispatch]);

    useEffect(() => {
        if (!searchText.length && !searchDate && !searchDateRange) {
            dispatch(setArchiveFilterWord(null));
            dispatch(setFilteredDate(null));
            dispatch(setIsFiltered(false));
            // TODO: TAS-1007
            // getBalance(null);
            return;
        }
        const delayDebounceFn = setTimeout(async () => {
            const filterDate = searchDate || searchDateRange;

            dispatch(setArchiveFilterWord(searchText));
            dispatch(setFilteredDate(filterDate));
            if (searchText) {
                await getSearchResult({
                    searchString: searchText,
                    timestamp_after: filterDate ? filterDate[0] : undefined,
                    timestamp_before: filterDate ? filterDate[1] : undefined,
                    ...pagination,
                    ...sortParams,
                }).unwrap();
            } else if (filterDate) {
                await getAllRecords({
                    timestamp_after: filterDate[0],
                    timestamp_before: filterDate[1],
                    ...pagination,
                    ...sortParams,
                }).unwrap();
            } else {
                dispatch(setIsFiltered(false));
            }
        }, 500);

        return () => clearTimeout(delayDebounceFn);
    }, [
        dispatch,
        getAllFiles,
        getAllRecords,
        getAllRequests,
        // TODO: TAS-1007
        // getBalance,
        getSearchResult,
        pagination,
        sortParams,
        searchDate,
        searchDateRange,
        searchText,
    ]);

    return (
        <div className='search_filter'>
            {/* PROTAI-744 закомментировано для показа */}
            {/* <SearchBar defaultText='Текст или название' searchText={searchText} setSearchText={setSearchText} /> */}
            <Calendar setSearchDate={handleSearchDate} setSearchDateRange={handleSearchDateRange} />
        </div>
    );
};

export default SearchFilter;
