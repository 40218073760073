export type LoginCredentials = {
    username: string;
    password: string;
    rememberMe?: boolean;
};

export type UsernameSchema = {
    first_name: string;
    last_name: string;
};

export type UserNameAndEmailSchema = {
    first_name: string;
    last_name: string;
};

export type FeedbackSchema = {
    message: string;
    email: string;
};

export const inputTypes = {
    text: 'text',
    password: 'password',
    email: 'email',
    number: 'number',
};

export interface ValidatedInputProps {
    minLength?: number;
    maxLength?: number;
    min?: number | string;
    max?: number | string;
    field: string;
    type?: string;
    pattern?: RegExp;
    required?: boolean;
    disabled?: boolean;
    className?: string;
    abilityToHide?: boolean;
    validateValue?: string;
    placeholder?: string;
    autofocus?: boolean;
    autocompleteOff?: boolean;
}

export interface InputProps {
    type?: string;
    placeholder?: string;
    label?: string;
    value?: string;
    pattern?: string;
    title?: string;
    isInactive?: boolean;
    onChange?: (val: string) => void;
    onBlur?: (val: string, isSubmitClick?: boolean) => void;
    isClearingAvailable?: boolean;
    onClear?: () => void;
    submitButtonRef?: React.RefObject<HTMLButtonElement>;
}

export interface ValidatedTextareaProps {
    minLength?: number;
    maxLength?: number;
    field: string;
    required?: boolean;
    disabled?: boolean;
    className?: string;
    placeholder?: string;
}
