import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { getTranscriptFontSize, setTranscriptFontSize } from 'helpers/LocalStorageActions';

export type demoViews = 'brief' | 'topics' | 'tasks' | 'highlights' | 'projects' | 'tables' | 'protocol';

interface TranscriptAppearanceState {
    fontSize: number;
    isProtocolVisible: boolean;
    isFullPageProtocol: boolean;
    isChangesVisible: boolean;
    demoView: demoViews | null;
    demoTextForCopy: string;
}

const initialState: TranscriptAppearanceState = {
    fontSize: parseInt(getTranscriptFontSize() || '16'),
    isChangesVisible: false,
    isProtocolVisible: true,
    isFullPageProtocol: false,
    demoView: null,
    demoTextForCopy: '',
};

const slice = createSlice({
    name: 'transcriptAppearance',
    initialState: initialState,
    reducers: {
        increaseFontSize(state) {
            if (state.fontSize < 24) {
                setTranscriptFontSize((state.fontSize + 1).toString());
                state.fontSize++;
            }
        },
        decreaseFontSize(state) {
            if (state.fontSize > 16) {
                setTranscriptFontSize((state.fontSize - 1).toString());
                state.fontSize--;
            }
        },
        toggleChangesVisability(state) {
            state.isChangesVisible = !state.isChangesVisible;
        },
        toggleFullPageProtocol(state) {
            state.isFullPageProtocol = !state.isFullPageProtocol;
        },
        changeProtocolVisibility(state) {
            state.isProtocolVisible = !state.isProtocolVisible;
        },
        changeDemoView(state, { payload }: PayloadAction<demoViews | null>) {
            if (payload === null || payload === state.demoView) {
                state.isFullPageProtocol = false;
            }
            state.demoView = payload === state.demoView ? null : payload;
        },
        setDemoTextForCopy(state, { payload }: PayloadAction<string>) {
            state.demoTextForCopy = payload;
        },
        transcriptAppearanceReset: () => initialState,
    },
    extraReducers: (builder) => {},
});

export const {
    increaseFontSize,
    decreaseFontSize,
    changeProtocolVisibility,
    toggleChangesVisability,
    toggleFullPageProtocol,
    changeDemoView,
    setDemoTextForCopy,
    transcriptAppearanceReset,
} = slice.actions;
export default slice.reducer;
