import { SectionsLayoutProps } from './SectionsLayout.interfaces';

import './SectionsLayout.scss';

const SectionsLayout: React.FC<SectionsLayoutProps> = (components): JSX.Element => (
    <>
        <div className='records_page_container'>
            {/* Закомментировано до появления папок записей на бэке */}
            {/* <Breadcrumbs /> */}
            {components.header}
            {components.control}
            <div className='page_content'>
                <section className='section_left'>{components.firstChildren}</section>
                <section className='section_right'>{components.secondChildren}</section>
            </div>
        </div>
    </>
);

export default SectionsLayout;
