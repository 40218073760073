import classNames from 'classnames';
import { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';

import { ReactComponent as LogoTextIcon } from 'assets/header/logo-text.svg';
import { ReactComponent as LogoIcon } from 'assets/header/logo.svg';
import { ReactComponent as ProfileIcon } from 'assets/menu/profile.svg';
import { headerHeight, isLargeMobile } from 'config/env';
import { useAppSelector } from 'hooks/useAppSelector';
import useOutsideClick from 'hooks/useOutsideClick';

import './Header.scss';

const Header: React.FC = () => {
    const dropdownRef = useRef<HTMLDivElement>(null);

    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const { isAuthorized } = useAppSelector((state) => state.user);

    useOutsideClick([dropdownRef], () => {
        setMenuIsOpen(false);
    });

    return (
        <nav className='main_page_header'>
            <ScrollLink
                smooth
                activeClass='link--active'
                offset={-headerHeight}
                spy
                duration={500}
                className='link'
                to='greeting'
            >
                <div className='logo'>
                    <LogoIcon className='logo-img' />
                    <LogoTextIcon className='logo-text' />
                </div>
            </ScrollLink>
            {isLargeMobile ? (
                <div className='dropdown_wrapper' ref={dropdownRef}>
                    <div
                        className={`hamburger ${menuIsOpen ? 'is-open' : 'is-closed'}`}
                        onClick={() => {
                            setMenuIsOpen(!menuIsOpen);
                        }}
                    >
                        <div className='burger-icon'>
                            <div className='burger-container'>
                                <span className='burger-bun-top'></span>
                                <span className='burger-filling'></span>
                                <span className='burger-bun-bot'></span>
                            </div>
                        </div>

                        <div className='burger-ring'>
                            <svg className='svg-ring'>
                                <path
                                    className='path'
                                    fill='none'
                                    strokeMiterlimit='10'
                                    strokeWidth='4'
                                    d='M 34 2 C 16.3 2 2 16.3 2 34 s 14.3 32 32 32 s 32 -14.3 32 -32 S 51.7 2 34 2'
                                />
                            </svg>
                        </div>

                        <svg width='0' height='0'>
                            <mask id='mask'>
                                <path
                                    xmlns='http://www.w3.org/2000/svg'
                                    fill='none'
                                    stroke='#ff0000'
                                    strokeMiterlimit='10'
                                    strokeWidth='4'
                                    d='M 34 2 c 11.6 0 21.8 6.2 27.4 15.5 c 2.9 4.8 5 16.5 -9.4 16.5 h -4'
                                />
                            </mask>
                        </svg>
                        <div className='path-burger'>
                            <div className='animate-path'>
                                <div className='path-rotation'></div>
                            </div>
                        </div>
                    </div>
                    <div className={classNames('dropdown_menu', { 'dropdown_menu--open': menuIsOpen })}>
                        <div className='links_mobile_container links'>
                            <ScrollLink
                                smooth
                                activeClass='link--active'
                                offset={-headerHeight}
                                spy
                                duration={500}
                                to='features'
                                className='link'
                                onClick={() => setMenuIsOpen(false)}
                            >
                                Возможности
                            </ScrollLink>
                            <ScrollLink
                                smooth
                                activeClass='link--active'
                                offset={-headerHeight}
                                spy
                                duration={500}
                                to='faq'
                                className='link'
                                onClick={() => setMenuIsOpen(false)}
                            >
                                Вопрос-ответ
                            </ScrollLink>
                            <ScrollLink
                                smooth
                                activeClass='link--active'
                                offset={-headerHeight}
                                spy
                                duration={500}
                                to='contact-us'
                                className='link'
                                onClick={() => setMenuIsOpen(false)}
                            >
                                Свяжитесь с нами
                            </ScrollLink>
                            {isAuthorized ? (
                                <>
                                    <Link to='/uploading' className='link'>
                                        Загрузка аудио
                                    </Link>
                                    <Link to='/archive' className='link'>
                                        Архив
                                    </Link>
                                </>
                            ) : (
                                <Link to='login/' className='link' onClick={() => setMenuIsOpen(false)}>
                                    <span className='text'>Войти</span>
                                    <ProfileIcon className='icon' />
                                </Link>
                            )}
                        </div>
                    </div>
                </div>
            ) : (
                <div className='links_desktop_container links'>
                    <ScrollLink
                        smooth
                        activeClass='link--active'
                        offset={-headerHeight}
                        spy
                        duration={500}
                        to='features'
                        className='link'
                    >
                        Возможности
                    </ScrollLink>
                    <ScrollLink
                        smooth
                        activeClass='link--active'
                        offset={-headerHeight}
                        spy
                        duration={500}
                        to='faq'
                        className='link'
                    >
                        Вопрос-ответ
                    </ScrollLink>
                    <ScrollLink
                        smooth
                        activeClass='link--active'
                        offset={-headerHeight}
                        spy
                        duration={500}
                        to='contact-us'
                        className='link'
                    >
                        Свяжитесь с нами
                    </ScrollLink>
                </div>
            )}
        </nav>
    );
};

export default Header;
