import { useCallback, useMemo } from 'react';
import { toast } from 'react-toastify';

// import DemoProjectsViewer from './demoProjectsViewer/DemoProjectsViewer';
import { DemoProtocolViewer } from './demoProtocolViewer/DemoProtocolViewer';
import DemoTablesViewer from './demoTablesViewer/DemoTablesViewer';
import DemoViewer from './demoViewer/DemoViewer';

import {
    // useLazyDownloadListOfTasksQuery,
    useShareProtocolMutation,
} from 'api/routes/ResultsApi';
import {
    useGenerateSummaryMutation,
    useGetBriefQuery,
    useGetProtocolQuery,
    useGetTablesQuery,
    useGetTasksQuery,
    useGetTopicsQuery,
    useUpdateSummaryMutation,
} from 'api/routes/SummaryApi';
// import { TRANSCRIPT_WORD_STATUS } from 'constants/common';
import { convertDemoTableToString } from 'helpers/ConvertDemoTableToString';
import { useAppSelector } from 'hooks/useAppSelector';
import { demoViews } from 'store/reducers/transcriptions/transcriptAppearanceSlice';

import './DemoProcessing.scss';

const DemoProcessing: React.FC = () => {
    const { demoView } = useAppSelector((state) => state.transcriptAppearance);
    const { editedTranscript } = useAppSelector((state) => state.transcript);
    const { file_id: fileId } = editedTranscript;

    const {
        isSuccess: isBriefSuccess,
        isFetching: isBriefFetching,
        data: briefText,
    } = useGetBriefQuery({ fileId }, { skip: demoView !== 'brief' });
    const {
        isSuccess: isTopicsSuccess,
        isFetching: isTopicsFetching,
        data: topicsText,
    } = useGetTopicsQuery({ fileId }, { skip: demoView !== 'topics' });
    const {
        isSuccess: isTasksSuccess,
        isFetching: isTasksFetching,
        data: tasksText,
    } = useGetTasksQuery({ fileId }, { skip: demoView !== 'tasks' });
    const {
        isSuccess: isTablesSuccess,
        isFetching: isTablesFetching,
        data: tablesText,
    } = useGetTablesQuery({ fileId }, { skip: demoView !== 'tables' });
    const {
        isSuccess: isProtocolSuccess,
        isFetching: isProtocolFetching,
        data: protocolFile,
    } = useGetProtocolQuery({ fileId }, { skip: demoView !== 'protocol' });

    const [generateBrief, { isLoading: isGenerateBriefLoading }] = useGenerateSummaryMutation();
    const [generateTopics, { isLoading: isGenerateTopicsLoading }] = useGenerateSummaryMutation();
    const [generateTasks, { isLoading: isGenerateTasksLoading }] = useGenerateSummaryMutation();
    const [generateTables, { isLoading: isGenerateTablesLoading }] = useGenerateSummaryMutation();
    const [generateProtocol, { isLoading: isGenerateProtocolLoading }] = useGenerateSummaryMutation();

    const [updateSummary] = useUpdateSummaryMutation();

    // const [downloadListOfTasks] = useLazyDownloadListOfTasksQuery();
    const [shareProtocolFn] = useShareProtocolMutation();

    // const getTranscriptText = useCallback(() => {
    //     const text: string[] = [];
    //     editedTranscript.result[0].result.forEach((replica) => {
    //         replica.words.forEach((word) => {
    //             word[4] !== TRANSCRIPT_WORD_STATUS.Removed && text.push(word[0]);
    //         });
    //     });

    //     return text.join(' ');
    // }, [editedTranscript.result]);

    const getViewerText = useCallback(
        (type: demoViews) => {
            switch (type) {
                case 'brief':
                    generateBrief({ fileId, view: type });
                    break;
                case 'topics':
                    generateTopics({ fileId, view: type });
                    break;
                case 'tasks':
                    generateTasks({ fileId, view: type });
                    break;
                case 'tables':
                    generateTables({ fileId, view: type });
                    break;
                case 'protocol':
                    generateProtocol({ fileId, view: type });
                    break;
            }

            //Закомментировано до появляения запроса суммаризации projects
            // if (type === 'projects') {
            //     getShortInfo({ text: getTranscriptText() });
            // }
        },
        [fileId, generateBrief, generateProtocol, generateTables, generateTasks, generateTopics],
    );

    const handleShare = useCallback(
        (emails: string[], data: string) =>
            shareProtocolFn({
                file_id: editedTranscript.file_id,
                data,
                emails,
            })
                .unwrap()
                .then(() => {
                    toast.success('Протокол отправлен', { position: 'bottom-right' });
                })
                .catch(() => {
                    toast.error('Ошибка при отправке протокола', { position: 'bottom-right' });
                    return Promise.reject();
                }),
        [shareProtocolFn, editedTranscript],
    );

    const handleUpdate = useCallback(
        async (value: string) => {
            if (!demoView) {
                return;
            }
            await updateSummary({ text: value, fileId: editedTranscript.file_id, view: demoView });
        },
        [updateSummary, editedTranscript.file_id, demoView],
    );

    const currentView = useMemo(() => {
        if (!demoView) {
            return null;
        }

        const title = demoView.charAt(0).toUpperCase() + demoView.slice(1);
        const props = {
            title: title,
            startProcessing: () => getViewerText(demoView),
            handleUpdate: handleUpdate,
        };
        switch (demoView) {
            case 'brief':
                return (
                    <DemoViewer
                        {...props}
                        isRequestLoading={isBriefFetching || isGenerateBriefLoading}
                        isRequestSuccess={isBriefSuccess && !isBriefFetching && briefText?.text != null}
                        handleShare={handleShare}
                    >
                        {briefText?.text}
                    </DemoViewer>
                );
            case 'topics':
                return (
                    <DemoViewer
                        {...props}
                        isRequestLoading={isTopicsFetching || isGenerateTopicsLoading}
                        isRequestSuccess={isTopicsSuccess && !isTopicsFetching && topicsText?.text != null}
                    >
                        {topicsText?.text}
                    </DemoViewer>
                );
            case 'tasks':
                return (
                    <DemoViewer
                        {...props}
                        isRequestLoading={isTasksFetching || isGenerateTasksLoading}
                        isRequestSuccess={isTasksSuccess && !isTasksFetching && tasksText?.text != null}
                        //Закомментировано до появления соответствующего функционала
                        // handleDownload={() => {
                        //     downloadListOfTasks({});
                        // }}
                    >
                        {tasksText?.text}
                    </DemoViewer>
                );
            case 'tables':
                return (
                    <DemoViewer
                        {...props}
                        isRequestLoading={isTablesFetching || isGenerateTablesLoading}
                        isRequestSuccess={isTablesSuccess && !isTablesFetching && tablesText?.text != null}
                        textToCopy={tablesText?.text ? convertDemoTableToString(tablesText?.text) : ''}
                        handleUpdate={undefined}
                    >
                        {tablesText?.text && <DemoTablesViewer tables={tablesText.text} />}
                    </DemoViewer>
                );
            case 'protocol':
                return (
                    <DemoViewer
                        {...props}
                        isRequestLoading={isProtocolFetching || isGenerateProtocolLoading}
                        isRequestSuccess={isProtocolSuccess && !isProtocolFetching && protocolFile?.text != null}
                        handleUpdate={undefined}
                    >
                        <DemoProtocolViewer />
                    </DemoViewer>
                );
            //Закомментировано для показа
            // case 'highlights':
            //     return <DemoViewer {...props}>{summaryText?.text}</DemoViewer>;
            // case 'projects':
            //     return (
            //         <DemoViewer {...props}>
            //             <DemoProjectsViewer
            //                 //Закомментировано до появляения запроса суммаризации tables и getShortInfo
            //                 // shortInfo={shortInfoText?.result || ''}
            //                 // projectsList={summaryText?.projects || []}
            //                 shortInfo={''}
            //                 projectsList={[]}
            //                 getTranscriptText={getTranscriptText}
            //             />
            //         </DemoViewer>
            //     );
        }
    }, [
        demoView,
        handleUpdate,
        getViewerText,
        isBriefFetching,
        isGenerateBriefLoading,
        isBriefSuccess,
        briefText?.text,
        handleShare,
        isTopicsFetching,
        isGenerateTopicsLoading,
        isTopicsSuccess,
        topicsText?.text,
        isTasksFetching,
        isGenerateTasksLoading,
        isTasksSuccess,
        tasksText?.text,
        isTablesFetching,
        isGenerateTablesLoading,
        isTablesSuccess,
        tablesText?.text,
        isProtocolFetching,
        isGenerateProtocolLoading,
        isProtocolSuccess,
        protocolFile?.text,
    ]);

    if (!demoView) {
        return <></>;
    }

    return <div className='demo-viewer'>{currentView}</div>;
};

export default DemoProcessing;
