import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { RequestSortParams, SortOrder, SortType } from 'types/common';

type useSortParamsReturn = RequestSortParams;

type UseSortParams = () => useSortParamsReturn;

const useSortParams: UseSortParams = () => {
    const [searchParams] = useSearchParams();

    const sortParams = useMemo(
        () => ({
            sort: (searchParams.get('sort') as SortType) ?? 'date',
            order: (searchParams.get('order') as SortOrder) ?? 'desc',
        }),
        [searchParams],
    );
    return sortParams;
};

export default useSortParams;
