export const saveCaretPosition = (
    nodePosition: React.MutableRefObject<Node | null | undefined>,
    offsetPosition: React.MutableRefObject<number>,
    additionalOffset: number = 0,
    offsetEndPosition: React.MutableRefObject<number> | null = null,
) => {
    if (document.getSelection()?.rangeCount) {
        if (
            document.getSelection()?.getRangeAt(0)?.startContainer.nodeName !== '#text' &&
            document.getSelection()?.getRangeAt(0)?.startContainer.hasChildNodes()
        ) {
            nodePosition.current = document.getSelection()?.getRangeAt(0)?.startContainer.childNodes[0];
            return;
        }

        nodePosition.current = document.getSelection()?.getRangeAt(0)?.startContainer;
        offsetPosition.current = (document.getSelection()?.getRangeAt(0)?.startOffset || 0) + additionalOffset;

        const childNodes = document
            .getSelection()
            ?.getRangeAt(0)
            ?.startContainer.parentElement?.closest('.replica__word')?.childNodes;

        if (childNodes) {
            for (let i = 0; i < childNodes.length; i++) {
                const node = !childNodes[i].hasChildNodes() ? childNodes[i] : childNodes[i].childNodes[0];
                if (nodePosition.current?.isSameNode(node)) {
                    break;
                } else {
                    offsetPosition.current += node.textContent?.length || 0;
                }
            }
        }

        if (offsetEndPosition) {
            offsetEndPosition.current = document.getSelection()?.getRangeAt(0)?.endOffset || 0;
        }
    }
};

export const restoreCaretPosition = (
    nodePosition: React.MutableRefObject<Node | null | undefined>,
    offsetPosition: React.MutableRefObject<number>,
) => {
    if (nodePosition.current && document.getSelection()?.rangeCount) {
        const s = document.getSelection();
        const range = document.createRange();

        if (nodePosition.current === null) {
            saveCaretPosition(nodePosition, offsetPosition);
            return;
        }

        try {
            range.setStart(nodePosition.current, 0);

            if (
                !document.body.contains(range.commonAncestorContainer) ||
                !nodePosition.current.textContent?.length ||
                offsetPosition.current > nodePosition.current.textContent?.length
            ) {
                saveCaretPosition(nodePosition, offsetPosition);
                range.setStart(nodePosition.current, offsetPosition.current);
            } else {
                range.setStart(nodePosition.current, offsetPosition.current);
            }

            s?.removeAllRanges();
            s?.addRange(range);
        } catch (e) {
            console.log('set range error');
        }
    }
};
