import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { resultsApi } from 'api/routes/ResultsApi';

interface AudioState {
    fileId: string;
    isFragmentPlaying: boolean;
    isReplicaPlaying: boolean;
    playingReplicaId: string | null;
    playingTimestepRange: [number | null, number | null];
    selectedTimestepRange: [number | null, number | null];
    selectedPosition: [number | null, number | null];
}

const initialState: AudioState = {
    fileId: '',
    isFragmentPlaying: false,
    isReplicaPlaying: false,
    playingReplicaId: null,
    playingTimestepRange: [null, null],
    selectedTimestepRange: [null, null],
    selectedPosition: [null, null],
};

const slice = createSlice({
    name: 'transcript',
    initialState: initialState,
    reducers: {
        playSelectedTimestepRange(state) {
            state.playingTimestepRange = state.selectedTimestepRange;
        },
        setSelectedTimestepRange(
            state,
            {
                payload: { startTimestep, endTimestep },
            }: PayloadAction<{ startTimestep: number | null; endTimestep: number | null }>,
        ) {
            state.selectedTimestepRange = [startTimestep, endTimestep];
        },
        setPlayingTimestepRange(
            state,
            {
                payload: { startTimestep, endTimestep },
            }: PayloadAction<{ startTimestep: number | null; endTimestep: number | null }>,
        ) {
            state.playingTimestepRange = [startTimestep, endTimestep];
        },
        setSelectedPosition(
            state,
            {
                payload: { leftPosition, topPosition },
            }: PayloadAction<{ leftPosition: number | null; topPosition: number | null }>,
        ) {
            state.selectedPosition = [leftPosition, topPosition];
        },
        setAudioFragmentPlaying(state, action: PayloadAction<boolean>) {
            state.isFragmentPlaying = action.payload;

            if (state.isReplicaPlaying) {
                state.isReplicaPlaying = false;
            }
        },
        setReplicaPlaying(
            state,
            { payload: { isPlaying, replicaId } }: PayloadAction<{ isPlaying: boolean; replicaId?: string }>,
        ) {
            state.isReplicaPlaying = isPlaying;
            state.isFragmentPlaying = isPlaying;
            state.playingReplicaId = replicaId || null;
        },
        audioReset: () => initialState,
    },
    extraReducers: (builder) => {
        builder.addMatcher(resultsApi.endpoints.result.matchFulfilled, (state, { payload }) => {
            state.fileId = payload.file_id;
        });
    },
});

export const {
    setPlayingTimestepRange,
    playSelectedTimestepRange,
    setSelectedTimestepRange,
    setAudioFragmentPlaying,
    setReplicaPlaying,
    setSelectedPosition,
    audioReset,
} = slice.actions;
export default slice.reducer;
