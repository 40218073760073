import Sorting from './sorting/Sorting';

import './ArchiveControl.scss';

const ArchiveControl: React.FC = () => {
    return (
        <>
            <div className='page_control'>
                <Sorting />
            </div>
        </>
    );
};

export default ArchiveControl;
