import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface PaginationState {
    limit: number;
    offset: number;
}

const initialState: PaginationState = {
    limit: 10,
    offset: 0,
};

export const paginationSlice = createSlice({
    name: 'pagination',
    initialState,
    reducers: {
        setLimit: (state, action: PayloadAction<number>) => {
            state.limit = action.payload;
        },
        setOffset: (state, action: PayloadAction<number>) => {
            state.offset = action.payload;
        },
    },
    extraReducers: {},
});

export const { setLimit, setOffset } = paginationSlice.actions;

export default paginationSlice.reducer;
