import React from 'react';

import { ConfirmDeleteModalProps } from './ConfirmDeleteModal.interface';

import { ModalWithDimming } from 'components/generic/modal/Modal';

const ConfirmDeleteModal: React.FC<ConfirmDeleteModalProps> = ({
    onClose,
    onOk,
    modalTitle = 'Удаление',
    confirmText,
    okButtonText = 'Да, удалить',
}) => {
    return (
        <ModalWithDimming
            hideModal={onClose}
            modalBody={
                <>
                    <p className='text_light'>{confirmText || 'Вы действительно хотите удалить запись?'}</p>
                    <p className='text_light'>Отменить это действие будет невозможно.</p>
                    <div className='modal_actions'>
                        <button type='button' className='btn btn_light' onClick={onClose}>
                            Отменить
                        </button>
                        <button type='button' className='btn btn_danger' onClick={onOk}>
                            {okButtonText}
                        </button>
                    </div>
                </>
            }
            modalTitle={modalTitle}
        />
    );
};

export default ConfirmDeleteModal;
