import classNames from 'classnames';
import { useCallback, useEffect, useRef, useState } from 'react';

import ChooseSpeakerModal from './ChooseSpeakerModal';

import { SpeakersDropdownProps } from '../../Transcript.interface';

import { ReactComponent as SpeakerIcon } from 'assets/man.svg';
import { ReactComponent as PLusIcon } from 'assets/plus.svg';

import Checkbox from 'components/generic/checkbox/Checkbox';
import { useAppSelector } from 'hooks/useAppSelector';

import useOutsideClick from 'hooks/useOutsideClick';

import './SpeakersDropdown.scss';

const SpeakersDropdown: React.FC<SpeakersDropdownProps> = ({ speaker, handleSpeakerChange }): JSX.Element => {
    const { speakersList: initList } = useAppSelector((state) => state.transcript);

    const dropdownRef = useRef<HTMLDivElement>(null);

    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
    const [isChooseModalOpen, setIsChooseModalOpen] = useState<boolean>(false);

    const [speakersList, setSpeakersList] = useState<string[]>(initList);
    const [selectedSpeaker, setSelectedSpeaker] = useState<string>(speaker);
    const [isChangeAllSpeakersEnable, setIsChangeAllSpeakersEnable] = useState<boolean>(false);

    useOutsideClick([dropdownRef], () => {
        if (!isChooseModalOpen) {
            handleChangeDropdownVisible(false);
        }
    });

    useEffect(() => {
        setSpeakersList(initList);
    }, [initList]);

    const handleChangeDropdownVisible = useCallback(
        (isVisible: boolean) => {
            setIsDropdownOpen(isVisible);
            if (!isVisible) {
                setSelectedSpeaker(speaker);
                setIsChangeAllSpeakersEnable(false);
            }
        },
        [speaker],
    );

    const handleAddSpeaker = useCallback(
        (item: string) => {
            setSpeakersList(Array.from(new Set([...speakersList, item])));
            setSelectedSpeaker(item);
        },
        [speakersList],
    );

    return (
        <>
            <div className='speakers_dropdown' ref={dropdownRef}>
                <div
                    tabIndex={0}
                    className={`speakers_dropdown__header`}
                    role='button'
                    onClick={() => {
                        handleChangeDropdownVisible(!isDropdownOpen);
                    }}
                >
                    <SpeakerIcon />
                </div>
                {isDropdownOpen && (
                    <div className='speakers_dropdown__content'>
                        <div className='speakers_dropdown__content__header'>
                            <span>Спикер</span>
                            <button
                                className='btn btn_primary btn_with_icon add-speaker-btn'
                                onClick={() => {
                                    setIsChooseModalOpen(true);
                                }}
                            >
                                <PLusIcon />
                            </button>
                        </div>
                        <ul className='speakers_dropdown__content__list'>
                            {speakersList.map((item, i) => (
                                <li
                                    className={classNames('speakers_dropdown__content__list__item', {
                                        selected: item === selectedSpeaker,
                                    })}
                                    key={i}
                                    onClick={() => setSelectedSpeaker(item)}
                                >
                                    {item}
                                </li>
                            ))}
                        </ul>
                        <Checkbox
                            id='change-all-speaker'
                            checked={isChangeAllSpeakersEnable}
                            setChecked={setIsChangeAllSpeakersEnable}
                            text='Для всех подобных'
                        />
                        <button
                            className='btn btn_secondary change-speaker-btn'
                            onClick={() => {
                                handleSpeakerChange(selectedSpeaker, isChangeAllSpeakersEnable);
                                handleChangeDropdownVisible(false);
                            }}
                        >
                            Заменить
                        </button>
                    </div>
                )}
            </div>
            {isChooseModalOpen && (
                <ChooseSpeakerModal setModalIsOpen={setIsChooseModalOpen} handleSpeakerChange={handleAddSpeaker} />
            )}
        </>
    );
};

export default SpeakersDropdown;
