import { useState } from 'react';

import Toggle from 'components/generic/toggle/Toggle';

import './ProfileSettings.scss';

const ProfileSettings: React.FC = () => {
    const [autoSaveOn, setAutoSaveOn] = useState(false);
    const [pushOn, setPushOn] = useState(false);
    const [emailOn, setEmailOn] = useState(false);
    return (
        <div className='profile_settings_section'>
            <p className='section_title'>Настройки</p>
            <Toggle id='autoSave' selected={autoSaveOn} setSelected={setAutoSaveOn} text='Включить автосохранение' />
            <p className='autosave_info'>
                Пояснение, что пользователь может использовать автоматическое сохранение во время редактирования
                стенограмм
            </p>
            <div className='notifications'>
                <div className='notifications_title'>Уведомления об окончании расшифровки</div>
                <Toggle id='push' selected={pushOn} setSelected={setPushOn} text='Push' />
                <Toggle id='email' selected={emailOn} setSelected={setEmailOn} text='Email' />
            </div>
        </div>
    );
};

export default ProfileSettings;
